import React, { Component } from 'react'
import { Nav, Navbar, NavDropdown, DropdownButton, Dropdown } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import Logo from './../dist/images/logo.svg';
import cookie from 'js-cookie';

class NavBar extends Component {


    constructor(props, context) {
        super(props, context);


        this.state = {
            firstname: '',
            lastname: '',
        }


    }

    handleLogout = (e) => {

        e.preventDefault();
        cookie.remove('token');
        cookie.remove('firstname');
        cookie.remove('lastname');
        this.props.loggout();
        window.location.reload()

    }


    render() {

        return (
            <div className="">
                <Navbar bg="white" expand="lg" style={{ zIndex: '999999999999' }}>
                    <Navbar.Brand href="/">

                        <img
                            src={Logo}
                            width="90"
                            height=""
                            className="d-inline-block align-topx mt-4"
                            alt="CE-IV LOGO"
                        />

                        {/* <span className="ml-3 inline-block pt-2 workSanMedium text-blue-800">Study Purse</span> */}


                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />

                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mx-auto">
                            <NavLink to="/" activeClassName="" className="biomelight mr-2 f-s-16 nav-link primaryColor2">Home</NavLink>
                            <NavLink to="/about" activeClassName="" className="biomelight mr-2 f-s-16 nav-link primaryColor2">About Us</NavLink>
                            <NavLink to="#" activeClassName="" className="biomelight mr-2 f-s-16 nav-link primaryColor2">Portfolio</NavLink>
                            {/* <NavLink to="/portfolio" activeClassName="" className="biomelight mr-2 f-s-16 nav-link primaryColor2">Portfolio</NavLink> */}
                            {/* <NavLink to="/team" activeClassName="" className="biomelight mr-2 f-s-16 nav-link primaryColor2">Team</NavLink> */}

                            <NavDropdown
                                id='drp'
                                title="Team"
                                className='biomelight primaryColor2'
                                menuVariant="ff">

                                <NavDropdown.Item href="advisory">Advisory</NavDropdown.Item>
                                <NavDropdown.Item href="team">The Ce-IV Team</NavDropdown.Item>
                            </NavDropdown>

                            <NavLink to="/media" activeClassName="" className="biomelight mr-2 f-s-16 nav-link primaryColor2">Media</NavLink>

                        </Nav>


                        <Nav className="mr-0">
                            {
                                !cookie.get('token') ?
                                    <>
                                        <NavLink to="/login" activeClassName="" className="biomelight mr-4 f-s-16 nav-link primaryColor2"> Login</NavLink>

                                        <NavLink to="/register" className="biomeBold text-center mr-0 navButton nav-linkx primaryColor2 font-bold px-5 py-2 border border-solid rounded-md primaryBg text-white hover:no-underline hover:bg-blue-300 hover:text-white" >Apply</NavLink>

                                        {/* <NavDropdown
                                id='drpx'
                                title="Apply"
                                className='biomelight text-white PrimaryBg'
                                menuVariant="ff">

                                <NavDropdown.Item href="advisory">Advisory</NavDropdown.Item>
                                <NavDropdown.Item href="team">The Ce-IV Team</NavDropdown.Item>
                            </NavDropdown> */}
                                    </>
                                    :

                                    <>
                                        <NavLink to="/dashboard" className="mr-6 biomeBold  nav-linkx py-2 px-3 rounded-full text-center  hover:no-underline primaryBg text-white">Dashboard</NavLink>

                                        <NavLink to="#" onClick={this.handleLogout} className="biomelight mr-6 navButton py-2 primaryColor px-3 no-underline rounded-full text-center borderx hover:bg-gray-300 hover:no-underline hover:text-white border-red-500">Logout</NavLink>

                                        <Link to="/profile" className='hover:no-underline hide-xs'>
                                            <div className="bg-gray-400 border h-10 w-10 md:h-10 md:w-10 pt-2 rounded-full mr-2 ml- text-center hide-xsx">
                                                <span className='font-bold text-black'>{cookie.get('firstname')[0] + '' + cookie.get('lastname')[0]}</span>
                                            </div>
                                        </Link>



                                    </>

                            }


                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </div>
        )
    }
}


//export default NavBar


const mapDispatchProps = dispatch => {

    return {
        loggout: () => dispatch({ type: 'SET_LOGOUT', })
    }
}

export default connect('', mapDispatchProps)(NavBar);



