import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import GetApi from '../api_request/Get';
import { ServerLink } from '../keys/Links';
import notifyError from '../inc/SnackbarError';
import Select from 'react-select'
import countryList from 'react-select-country-list';
import Error from "../inc/Error";

import FeatherIcon from "feather-icons-react";
import postApi, { postApiMultipart } from '../api_request/Post';
import Founder from './Founder';
import Member from './Member';
import NavBar from '../inc/Navbar';
import cookie from 'js-cookie';
import { Spinner } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';


//import 'react-dropzone-uploader/dist/styles.css'


class Apply extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            step: 1,
            isLoading: false,
            number_founders: 1,
            founders: [],
            key_member: 1,
            members: [],
            fields: {},
            errors: {},
            mvp: "0",
            capital_raise: "0",
            product_type: "New Innovation",
            regulatory_license: "0",
            capital_raise: "0",
            issued_investor_stock: "0",
            meeting: "1",
            goverment_grant: "0",
            accelerator_program: "0",
            goverment_grant: "0",
            is_revenue: "0",
            files: [],
            revenue_streams:[],
            pitch_deck: false,
            website: ''
            //uploadSuccess: true,
        }

    }

    preview_images = {
        "application/pdf": ""
    }


    /*
    acceptFiles = (acceptedFiles) => {

        console.log(acceptedFiles);

        this.setState({
            files: acceptedFiles.map(file => Object.assign(file, { preview: URL.createObjectURL(file) }))
        });



        const formData = new FormData();
        formData.append('file', acceptedFiles[0]);

        const xhr = new XMLHttpRequest();
        xhr.open('POST', ServerLink + 'api/upload-file', true);
        xhr.setRequestHeader('Authorization', `Bearer ${cookie.get('token')}`);

        xhr.upload.addEventListener('progress', (event) => {
            if (event.lengthComputable) {
                const percentage = (event.loaded / event.total) * 100;
                this.setState({ uploadProgress: percentage });
            }
        });

        xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE) {
                if (xhr.status === 200) {
                    
                    const response = JSON.parse(xhr.responseText);
                    if (response.success === true) {
                        notifyError(response.message, 'success', 'top-center');
                        this.setState({ uploadSuccess: true })
                    } else {
                        notifyError(response.message, 'error', 'top-center');
                        this.setState({ uploadSuccess: false })
                    }
                    this.setState({ isLoading: false });
                } else {
                    notifyError('Error occurred during file upload.', 'error', 'top-center');
                    this.setState({ isLoading: false });
                    this.setState({ uploadSuccess: false })
                }
            };

            xhr.send(formData);
        };

    }

    */



    acceptFiles = (acceptedFiles) => {

        console.log(acceptedFiles);

        this.setState({
            files: acceptedFiles.map(file => Object.assign(file, { preview: URL.createObjectURL(file) }))
        });

        const formData = new FormData();
        formData.append('file', acceptedFiles[0]);

        const xhr = new XMLHttpRequest();
        xhr.open('POST', ServerLink + 'api/upload-file', true);
        xhr.setRequestHeader('Authorization', `Bearer ${cookie.get('token')}`);

        xhr.upload.addEventListener('progress', (event) => {
            if (event.lengthComputable) {
                const percentage = (event.loaded / event.total) * 100;
                this.setState({ uploadProgress: percentage });
            }
        });

        xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE) {
                if (xhr.status === 200) {
                    const response = JSON.parse(xhr.responseText);
                    if (response.status === true) {

                        this.setState({ uploadSuccess: false })
                        notifyError(response.message, 'success', 'top-center');


                        console.log(response);
                    } else {
                        notifyError(response.message, 'error', 'top-center');
                        this.setState({ uploadSuccess: true })

                        console.log(response);
                    }
                    this.setState({ isLoading: false });
                } else {
                    notifyError('Error occurred during file upload.', 'error', 'top-center');
                    this.setState({ isLoading: false });
                    this.setState({ uploadSuccess: false })

                    console.log(response);
                }
            }
        };

        xhr.send(formData);
    };


    removeFile = () => {
        this.setState({ files: [] })
    }

    updateDynamicInput = (index, info, state) => {
        this.setState((prevState) => {
            const updatedState = [...prevState[state]];
            updatedState[index - 1] = info;

            return { [state]: updatedState };
        });
    }

    componentDidMount() {

        this.getSaveForm();

        this.country();
    }

    emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;


    country = (e) => {

        this.setState({
            countryList: countryList().getData()
        })
    }


    getSaveForm = (e) => {
        GetApi(ServerLink + 'api/get-form').then(

            res => {

                if (res.status === true) {
                    this.setState({
                        saveForm: res.data,
                        loading: false,
                        ...res.data[0],
                        files: [{ ...res.data[0]?.pitch, saved: true }]
                    })
                }
                else {
                    //notifyError(res.message, 'error', 'top-center')
                    this.setState({
                        loading: false
                    })
                }
            }

        ).catch(e => {
            console.log(e)

            notifyError('Oops.. Something went wrong, check your internet connection', 'error', 'top-center')
            this.setState({
                isLoading: false
            })
        })
    }


    prevStep = () => {
        const { step } = this.state;

        this.setState({
            step: step - 1,
        });
    };


    nextStep = () => {
        const { step } = this.state;

        this.setState({
            step: step + 1,
        });
    }


    handleInput = (e) => {
        if (e.target.type !== "radio") e.preventDefault();
        const name = e.target.name;
        let value = e.target.value;
        if (parseInt(e.target.max) && value > parseInt(e.target.max)) {
            value = e.target.max;
        }
        if (e.target.type === "checkbox") {
            const selected_values = this.state[name] || []
            if (e.target.checked) {
                this.setState({ [name]: [...selected_values, value] });
            } else {
                this.setState({
                    [name]: selected_values.filter(stream => stream !== value),
                });
            }
        } else {
            this.setState({ [name]: value });
        }
    };


    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }


    addElement = () => {
        const { name, email, phone } = this.state;
        this.setState((prevState) => ({
            users: prevState.users.concat({
                key: Date.now(),
                name,
                email,
                phone
            })
        }));
    };


    removeElement = (id) => {
        this.setState((prevState) => ({
            users: prevState.users.filter((user) => user.key !== id)
        }));
    };


    validateSubmitStep1 = (e) => {
        //e.defaultValue();

        ///let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        if (!this.state.description) {
            formIsValid = false;
            errors["description"] = "This field cannot be empty";
        }

        if (!this.state.product_description) {
            formIsValid = false;
            errors["product_description"] = "This field cannot be empty";
        }

        if (!this.state.industry) {
            formIsValid = false;
            errors["industry"] = "This field cannot be empty";
        }

        if (!this.state.problem_solving) {
            formIsValid = false;
            errors["problem_solving"] = "This field cannot be empty";
        }

        if (!this.state.usp) {
            formIsValid = false;
            errors["usp"] = "This field cannot be empty";
        }

        if (!this.state.service_leverage) {
            formIsValid = false;
            errors["service_leverage"] = "This field cannot be empty";
        }

        if (!this.state.mvp) {
            formIsValid = false;
            errors["mvp"] = "This field cannot be empty";
        }



        if (!this.state.company) {
            formIsValid = false;
            errors["company"] = "This field cannot be empty";
        }

        if (!this.state.address) {
            formIsValid = false;
            errors["address"] = "This field cannot be empty";
        }
        if (!this.state.state) {
            formIsValid = false;
            errors["state"] = "This field cannot be empty";
        }

        if (!this.state.city) {
            formIsValid = false;
            errors["city"] = "This field cannot be empty";
        }

        if (!this.state.country) {
            formIsValid = false;
            errors["country"] = "This field cannot be empty";
        }
        if (!this.state.website) {
            formIsValid = false;
            errors["website"] = "This field cannot be empty";
        }

        if (!this.state.files.length || !this.state.uploadSuccess && !(this.state.files[0] && this.state.files[0]?.saved)) {
            formIsValid = false;
            errors["pitch_deck"] = "Please upload your pitch deck";
        }

        this.setState({ errors: errors });
        //return formIsValid;

        if (formIsValid) {

            this.setState({
                isLoading: true,
            })

            const data = {
                company: this.state.company,
                address: this.state.address,
                city: this.state.city,
                state: this.state.state,
                country: this.state.country.label,
                website: this.state.website,
                description: this.state.description,
                product_description: this.state.product_description,
                industry: this.state.industry,
                problem_solving: this.state.problem_solving,
                usp: this.state.usp,
                mvp: this.state.mvp,
                service_leverage: this.state.service_leverage,
                step: 1
            }


            postApi(ServerLink + 'api/save-form-steps', data).then(
                res => {

                    if (res.status === true) {
                        this.setState({ isLoading: false })

                        this.nextStep();
                    }
                    else {
                        notifyError(res.message, 'error', 'top-center');
                    }
                }
            ).catch(e => {
                notifyError(e, 'error', 'top-center');

                this.setState({
                    isLoading: false,
                })

                console.log(e)
            })
        }




    }


    validateSubmitStep2 = () => {

        let errors = {};
        let formIsValid = true;
        const { founders } = this.state;

        if (!this.state.incorporation_country) {
            formIsValid = false;
            errors["incorporation_country"] = "This field cannot be empty";
        }

        if (!this.state.incorporation_year) {
            formIsValid = false;
            errors["incorporation_year"] = "This field cannot be empty. Input the incorporation year";
        }

        if (this.state.number_founders <= 0) {
            formIsValid = false;
            errors["number_founders"] = "This field cannot be empty. Input the number of founder(s)";
        }


        if (!this.state.ceo_name) {
            formIsValid = false;
            errors["ceo_name"] = "This field cannot be empty. Please input the CEO Name";
        }


        if (!this.state.ceo_gender) {
            formIsValid = false;
            errors["ceo_gender"] = "This field cannot be empty. Please input the CEO Gender";
        }


        if (!this.state.ceo_email) {
            formIsValid = false;
            errors["ceo_email"] = "This field cannot be empty. Please input the CEO Email";
        }

        if (!this.state.ceo_phone) {
            formIsValid = false;
            errors["ceo_phone"] = "This field cannot be empty. Please input the CEO Phone";
        }

        for (let i = founders.length; i < this.state.number_founders; i++) {
            founders.push({
                founder_name: "",
                founder_title: "",
                founder_email: ""
            });
        }

        const founderErrors = {}
        founders.forEach((obj, index) => {
            Object.entries(obj).forEach(([key, value]) => {
                if (!value) {
                    if (!founderErrors[index]) {
                        founderErrors[index] = {};
                    }

                    if (key === "founder_name") {
                        founderErrors[index][key] = "This field cannot be empty.";
                    } else if (key === "founder_title") {
                        founderErrors[index][key] = "This field cannot be empty.";
                    } else if (key === "founder_email") {
                        founderErrors[index][key] = "Please enter a valid email address.";
                    } else {
                        founderErrors[index][key] = "This field cannot be empty.";
                    }
                } else if (key === "member_email" && !this.emailRegex.test(value)) {
                    founderErrors[index][key] = "Please enter a valid email address.";
                }
            });
        });

        if (Object.keys(founderErrors).length > 0) {
            errors.founders = founderErrors;
        }

        this.setState({ errors: errors });

        if (formIsValid) {

            this.setState({
                isLoading: true,
            })


            const data = {
                incorporation_country: this.state.incorporation_country.label,
                incorporation_year: this.state.incorporation_year,
                number_founder: this.state.number_founders,
                ceo_name: this.state.ceo_name,
                ceo_gender: this.state.ceo_gender,
                ceo_email: this.state.ceo_email,
                ceo_phone: this.state.ceo_phone,
                "founder_name[]": this.state.founders.map(key_member => key_member.founder_name),
                "founder_title[]": this.state.founders.map(key_member => key_member.founder_title),
                "founder_email[]": this.state.founders.map(key_member => key_member.founder_email),
                step: 2
            }


            postApi(ServerLink + 'api/save-form-steps', data).then(
                res => {
                    if (res.status === true) {
                        this.setState({ isLoading: false })
                        this.nextStep();
                    }
                    else {
                        notifyError(res.message, 'error', 'top-center');
                    }
                    console.log(e)
                }
            ).catch(e => {
                notifyError(e, 'error', 'top-center');
                this.setState({
                    isLoading: false,
                })
                console.log(e)
            })
        }
    }

    validateSubmitStep3 = () => {
        const errors = {};
        const { founder_equity, employee_size, key_member, business_stage, fundraising_round, members } = this.state;

        // Validate Founder's equity share
        if (!founder_equity) {
            errors.founder_equity = "Founder's equity share is required.";
        }

        // Validate Employee Size
        if (!parseInt(employee_size)) {
            errors.employee_size = "Employee size is required.";
        }

        // Validate Number of Member(s)
        if (!parseInt(key_member)) {
            errors.key_member = "Number of member(s) is required.";
        }

        // Validate Current Business Stage
        if (!business_stage) {
            errors.business_stage = "Current business stage is required.";
        }

        // Validate Fundraising Round
        if (!fundraising_round) {
            errors.fundraising_round = "Fundraising round is required.";
        }

        for (let i = members.length; i < key_member; i++) {
            members.push({
                member_name: "",
                member_title: "",
                member_email: ""
            });
        }

        const memberErrors = {}
        members.forEach((obj, index) => {
            Object.entries(obj).forEach(([key, value]) => {
                if (!value) {
                    if (!memberErrors[index]) {
                        memberErrors[index] = {};
                    }

                    if (key === "member_name") {
                        memberErrors[index][key] = "This field cannot be empty.";
                    } else if (key === "member_title") {
                        memberErrors[index][key] = "This field cannot be empty.";
                    } else if (key === "member_email") {
                        memberErrors[index][key] = "Please enter a valid email address.";
                    } else {
                        memberErrors[index][key] = "This field cannot be empty.";
                    }
                } else if (key === "member_email" && !this.emailRegex.test(value)) {
                    if (!memberErrors[index]) {
                        memberErrors[index] = {};
                    }
                    memberErrors[index][key] = "Please enter a valid email address.";
                }
            });
        });


        // Validate Members
        // const membersErrors = {};
        // for (let i = 1; i <= key_member; i++) {
        //   const memberData = members[i];

        //   if (!memberData || !memberData.name) {
        //     membersErrors[i] = `Name of member ${i} is required.`;
        //   }
        // }

        if (Object.keys(memberErrors).length > 0) {
            errors.members = memberErrors;
        }

        if (Object.keys(errors).length > 0) {
            this.setState({ errors });
        } else {
            // Validation passed, proceed to the next step
            // Call the function to handle the submission of Step 3 data here
            this.setState({
                isLoading: true,
            })

            const data = {
                step: 3,
                founder_equity: this.state.founder_equity,
                employee_size: this.state.employee_size,
                business_stage: this.state.business_stage,
                fundraising_round: this.state.fundraising_round,
                "member_name[]": members.map(key_member => key_member.member_name),
                "member_title[]": members.map(key_member => key_member.member_title),
                "member_email[]": members.map(key_member => key_member.member_email),
                key_member: this.state.key_member,
            }


            postApi(ServerLink + 'api/save-form-steps', data).then(
                res => {
                    if (res.status === true) {
                        this.setState({ isLoading: false })
                        this.nextStep();
                    }
                    else {
                        notifyError(res.message, 'error', 'top-center');
                    }
                }
            ).catch(e => {
                notifyError(e, 'error', 'top-center');
                this.setState({
                    isLoading: false,
                })
                console.log(e)
            })
        }
    };


    validateSubmitStep4 = () => {
        let errors = {};
        let formIsValid = true;

        if (!this.state.investment_ask) {
            formIsValid = false;
            errors["investment_ask"] = "This field cannot be empty. Input the investment ask";
        }

        if (!this.state.currency) {
            formIsValid = false;
            errors["currency"] = "This field cannot be empty. Select the currency";
        }

        if (!this.state.company_valuation) {
            formIsValid = false;
            errors["company_valuation"] = "This field cannot be empty. Input the estimated company valuation";
        }

        if (!this.state.revenue_streams || this.state.revenue_streams.length === 0) {
            formIsValid = false;
            errors["revenue_streams"] = "Select at least one revenue stream";
        }

        if (this.state.revenue_streams && this.state.revenue_streams.includes("Other") && !this.state.other_revenue_stream) {
            formIsValid = false;
            errors["other_revenue_stream"] = "This field cannot be empty. Input the other revenue stream";
        }

        if (this.state.is_revenue === null || this.state.is_revenue === undefined) {
            formIsValid = false;
            errors["is_revenue"] = "Select whether the business is generating any revenue";
        }

        if (this.state.is_revenue === "1" && !this.state.monthly_revenue) {
            formIsValid = false;
            errors["monthly_revenue"] = "This field cannot be empty. Select the monthly revenue";
        }

        if (!this.state.how_revenue) {
            formIsValid = false;
            errors["how_revenue"] = "This field cannot be empty. Input how the product makes money";
        }

        if (this.state.accelerator_program === null || this.state.accelerator_program === undefined) {
            formIsValid = false;
            errors["accelerator_program"] = "Select whether the business is in accelerator programs";
        }

        if (this.state.accelerator_program === "1" && !this.state.accelerator_attended) {
            formIsValid = false;
            errors["accelerator_attended"] = "Please input the name of the accelerator program you attended and how long you attended it";
        }

        this.setState({ errors });

        if (formIsValid) {
            this.setState({
                isLoading: true,
            });

            const data = {
                step: 4,
                investment_ask: this.state.investment_ask,
                currency: this.state.currency,
                company_valuation: this.state.company_valuation,
                revenue_streams: this.state.revenue_streams.toString(),
                other_revenue_stream: this.state.other_revenue_stream,
                is_revenue: this.state.is_revenue,
                monthly_revenue: this.state.monthly_revenue,
                how_revenue: this.state.how_revenue,
                accelerator_program: this.state.accelerator_program,
                accelerator_attended: this.state.accelerator_attended,
                
            };

            console.log(data);
            
            postApi(ServerLink + 'api/save-form-steps', data)
                .then(res => {
                    if (res.status === true) {
                        this.setState({ isLoading: false });
                        this.nextStep();
                    } else {
                        notifyError(res.message, 'error', 'top-center');
                    }

                    console.log(res);
                })
                .catch(e => {
                    notifyError(e, 'error', 'top-center');
                    console.log('Oops: '+ e);
                    this.setState({
                        isLoading: false,
                    });
                });

                
        }
    };


    validateSubmitStep5 = () => {
        const { product_type, competitor, competitor_website, regulatory_license, type_funding, capital_raise, issued_investor_stock, goverment_grant, goverment_grant_information, meeting } = this.state;
        const errors = {};

        if (!product_type) {
            errors.product_type = "Product type is required.";
        }

        if (!competitor) {
            errors.competitor = "First competitor is required.";
        }

        if (!competitor_website) {
            errors.competitor_website = "First competitor website is required.";
        }

        if (regulatory_license === null || regulatory_license === undefined) {
            errors.regulatory_license = "Please select if a regulatory license is required.";
        }

        if (!type_funding) {
            errors.type_funding = "Type of funding is required.";
        }

        if (capital_raise === null || capital_raise === undefined) {
            errors.capital_raise = "Please select if the business has raised any capital.";
        }

        if (issued_investor_stock === null || issued_investor_stock === undefined) {
            errors.issued_investor_stock = "Please select if the business has issued stocks to investors.";
        }

        if (!meeting) {
            errors.meeting = "Please indicate if you want us to schedule a meeting after your application is submitted.";
        }

        if (goverment_grant === null || goverment_grant === undefined) {
            errors.goverment_grant = "Please select if the company has received any government grant(s).";
        }

        if (goverment_grant === "1" && !goverment_grant_information) {
            errors.goverment_grant_information = "Please input additional information concerning your government grant"
        }

        // Update state with errors (if any)
        this.setState({ errors });

        // Return true if there are no errors, indicating successful validation
        if (Object.keys(errors).length) {
        } else {
            this.setState({
                isLoading: true,
            })

            const data = {
                step: 5,
                product_type: this.state.product_type,
                competitor: this.state.competitor,
                competitor_website: this.state.competitor_website,
                competitor2: this.state.competitor2,
                competitor2_website: this.state.competitor2_website,
                regulatory_license: this.state.regulatory_license,
                type_funding: this.state.type_funding,
                capital_raise: this.state.capital_raise,
                issued_investor_stock: this.state.issued_investor_stock,
                goverment_grant: this.state.goverment_grant,
                meeting: this.state.meeting,
                goverment_grant_information: this.state.goverment_grant ? this.state.goverment_grant_information : null,
                comment: this.state.comment
            };


            postApi(ServerLink + 'api/save-form-steps', data).then(
                res => {
                    if (res.status === true) {
                        this.setState({ isLoading: false })
                        this.redirectHome();
                        //this.nextStep();

                        this.setState({
                            isLoading: false,
                        })
                    }
                    else {
                        this.setState({ isLoading: false })
                        notifyError(res.message, 'error', 'top-center');
                    }
                }
            ).catch(e => {
                notifyError(e, 'error', 'top-center');
                this.setState({
                    isLoading: false,
                })
                console.log(e)
            })
        }
    };



    redirectHome = () => {
        //return <Redirect to="/dashboard" />
        this.props.history.push('/complete')
    }

    currentStep = () => {

        const {
            step,
            isLoading,
            country,
            incorporation_country,
            incorporation_year
        } = this.state;

        const acceptedFileTypes = {
            'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
            'application/pdf': ['.pdf'],
            // 'application/msword': ['.doc'],
            // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
            // 'application/vnd.ms-powerpoint': ['.ppt'],
            // 'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
        };

        if (step === 1) {
            return (

                <>
                    <div className=''>

                        <div className='mt-3'>
                            <label className='primaryColor f-Varela font-bold'>Describe your company (70 characters)? <span className='text-red-400'>*</span></label>
                            <textarea className='w-full px-3 formBorder border-gray-500 rounded-md focus:bg-blue-100 focus:outline-none' name='description' defaultValue={this.state.description} onChange={this.handleInput} rows={5}></textarea>
                            <Error error={this.state.errors["description"] ? this.state.errors["description"] : null} />
                        </div>

                        <div className='mt-4'>
                            <label className='primaryColor f-Varela font-bold'>Describe your product or service? <span className='text-red-400'>*</span></label>
                            <textarea className='w-full px-3 formBorder border-gray-500 rounded-md focus:bg-blue-100 focus:outline-none' name='product_description' defaultValue={this.state.product_description} onChange={this.handleInput} rows={5}></textarea>
                            <Error error={this.state.errors["product_description"] ? this.state.errors["product_description"] : null} />
                        </div>

                        <div className='mt-4'>
                            <label className='primaryColor biomelight font-bold'>Industry/ Sub-sector? <span className='text-red-400'>*</span></label>
                            <input className='w-full formBorder border-gray-500 rounded-md py-2 px-3 focus:bg-blue-100 focus:outline-none' defaultValue={this.state.industry} name='industry' onChange={this.handleInput} />
                            <Error error={this.state.errors["industry"] ? this.state.errors["industry"] : null} />
                        </div>

                        <div className='mt-4'>
                            <label className='primaryColor biomelight font-bold'>What problem is the product or service solving? <span className='text-red-400'>*</span></label>
                            <textarea className='w-full px-3 formBorder border-gray-500 rounded-md focus:bg-blue-100 focus:outline-none' name='problem_solving' defaultValue={this.state.problem_solving} onChange={this.handleInput} rows={5}></textarea>
                            <Error error={this.state.errors["problem_solving"] ? this.state.errors["problem_solving"] : null} />
                        </div>

                        <div className='mt-4'>
                            <label className='primaryColor f-Varela font-bold'>What is the Unique Selling Proposition (USP) of the product service?  <span className='text-red-400'>*</span></label>
                            <textarea className='w-full px-3 formBorder border-gray-500 rounded-md focus:bg-blue-100 focus:outline-none' name='usp' defaultValue={this.state.usp} onChange={this.handleInput} rows={5}></textarea>
                            <Error error={this.state.errors["usp"] ? this.state.errors["usp"] : null} />
                        </div>

                        <div className='mt-4'>
                            <label className='primaryColor f-Varela font-bold'>What technology does the product or service leverage? <span className='text-red-400'>*</span></label>
                            <select className='w-full py-2 px-3 formBorder border-gray-500 rounded-md focus:bg-blue-100 focus:outline-none' name='service_leverage' defaultValue={this.state.service_leverage} onChange={this.handleInput}>
                                <option value="">Select Option</option>
                                <option value="IOT (Internet of Things)">IOT (Internet of Things)</option>
                                <option value="Cloud Computing">Cloud Computing</option>
                                <option value="Artificial Intelligence & Machine Learning">Artificial Intelligence & Machine Learning</option>
                                <option value="Quantum Computing">Quantum Computing</option>
                                <option value="Data Collection and Real-time processing">Data Collection and Real-time processing</option>
                            </select>
                            <Error error={this.state.errors["service_leverage"] ? this.state.errors["service_leverage"] : null} />
                        </div>

                        <div className='mt-4'>
                            <label className='primaryColor biomelight font-bold'>Is there a Minimum Viable Product? <span className='text-red-400'>*</span></label>
                            <div>
                                <label htmlFor="f-mvp" className="l-radio">
                                    <input type="radio" id="f-mvp" name="mvp" onChange={this.handleInput} value={1} tabIndex="1" defaultChecked={this.state.mvp === "1"} checked={this.state.mvp === "1"} />
                                    <span>Yes</span>
                                </label>
                                <label htmlFor="s-mvp" className="l-radio">
                                    <input type="radio" id="s-mvp" name="mvp" onSelect={this.handleInput} value={0} tabIndex="2" defaultChecked={this.state.mvp === "0"} checked={this.state.mvp === "0"} />
                                    <span>No</span>
                                </label>
                            </div>

                            <Error error={this.state.errors["mvp"] ? this.state.errors["mvp"] : null} />
                        </div>

                        <div className='mt-4'>
                            <label className='primaryColor biomelight font-bold'>Upload Pitch deck<span className='text-red-400'>*</span> <small>(PDF File; Not more than 10MB in size)</small></label>
                            <div>
                                <Dropzone accept={acceptedFileTypes} onDrop={acceptedFiles => this.acceptFiles(acceptedFiles)}>
                                    {({ getRootProps, getInputProps }) => (
                                        <section className='border-2 border-gray-500 rounded-md border-dotted w-full text-center cursor-pointer f-Varela text-sm' style={{
                                            borderColor: this.state.files && this.state.files[0]?.saved ? "#4bce61" : "rgb(107, 114, 128)"
                                        }}>
                                            <div {...getRootProps()}>
                                                <input name='file' {...getInputProps()} />
                                                {
                                                    this.state.files.length ?
                                                        this.state.files.map(file => (
                                                            <div className="flex items-center gap-4 px-3 py-2 w-full">
                                                                {
                                                                    file.saved ? (
                                                                        <div className="flex items-center justify-between gap-5 w-full fill-red-4">
                                                                            <p className='text-[#494d50] mb-0'>
                                                                                {this.state.company || "Your Companny"}'s Pitch Deck
                                                                            </p>
                                                                            <button onClick={this.removeFile}>
                                                                                <svg stroke="1" fill="#ef4444" clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="1" viewBox="0 0 24 24" width="32" height="32" xmlns="http://www.w3.org/2000/svg"><path d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 7.425 2.717-2.718c.146-.146.339-.219.531-.219.404 0 .75.325.75.75 0 .193-.073.384-.219.531l-2.717 2.717 2.727 2.728c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.384-.073-.53-.219l-2.729-2.728-2.728 2.728c-.146.146-.338.219-.53.219-.401 0-.751-.323-.751-.75 0-.192.073-.384.22-.531l2.728-2.728-2.722-2.722c-.146-.147-.219-.338-.219-.531 0-.425.346-.749.75-.749.192 0 .385.073.531.219z" fill-rule="nonzero" /></svg>
                                                                            </button>
                                                                        </div>
                                                                    ) : (
                                                                        <>
                                                                            <div className='w-16 h-16 bg-gray-200'>
                                                                            </div>
                                                                            <div className='flex flex-col gap-1 w-full'>
                                                                                <div className='flex justify-between items-center w-full'>
                                                                                    <div className="flex flex-col items-start gap-2 text-[#494d50] text-sm">
                                                                                        <p className="font-bold mb-0">{(file.size / 1048576).toFixed(1)} <span className="font-normal">MB</span></p>
                                                                                        <p className="mb-0">{file.name}</p>
                                                                                    </div>
                                                                                    <button onClick={this.removeFile}>
                                                                                        <svg stroke="1" fill="#8e98a2" clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="1" viewBox="0 0 24 24" width="40" height="40" xmlns="http://www.w3.org/2000/svg"><path d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 7.425 2.717-2.718c.146-.146.339-.219.531-.219.404 0 .75.325.75.75 0 .193-.073.384-.219.531l-2.717 2.717 2.727 2.728c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.384-.073-.53-.219l-2.729-2.728-2.728 2.728c-.146.146-.338.219-.53.219-.401 0-.751-.323-.751-.75 0-.192.073-.384.22-.531l2.728-2.728-2.722-2.722c-.146-.147-.219-.338-.219-.531 0-.425.346-.749.75-.749.192 0 .385.073.531.219z" fill-rule="nonzero" /></svg>
                                                                                    </button>
                                                                                </div>
                                                                                <div className="relative rounded-sm w-0 h-1" style={{
                                                                                    width: `${this.state.uploadProgress}%`,
                                                                                    backgroundColor: "#4bce61",
                                                                                }}>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )
                                                                }
                                                            </div>
                                                        )) : (
                                                            <div className='flex flex-col gap-4 items-center justify-center py-5 px-10'>
                                                                <svg fill="#6B7280" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M16 16h-3v5h-2v-5h-3l4-4 4 4zm3.479-5.908c-.212-3.951-3.473-7.092-7.479-7.092s-7.267 3.141-7.479 7.092c-2.57.463-4.521 2.706-4.521 5.408 0 3.037 2.463 5.5 5.5 5.5h3.5v-2h-3.5c-1.93 0-3.5-1.57-3.5-3.5 0-2.797 2.479-3.833 4.433-3.72-.167-4.218 2.208-6.78 5.567-6.78 3.453 0 5.891 2.797 5.567 6.78 1.745-.046 4.433.751 4.433 3.72 0 1.93-1.57 3.5-3.5 3.5h-3.5v2h3.5c3.037 0 5.5-2.463 5.5-5.5 0-2.702-1.951-4.945-4.521-5.408z" /></svg>
                                                                <p className='mb-0 biomelight'>Drop your file here or click here to upload</p>
                                                            </div>
                                                        )
                                                }
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>

                                <div className=''>

                                </div>
                            </div>
                            <Error error={this.state.errors["pitch_deck"] ? this.state.errors["pitch_deck"] : null} />
                        </div>

                        <div className='mt-4'>
                            <label className='primaryColor biomelight font-bold'>Company Name<span className='text-red-400'>*</span></label>
                            <div>
                                <input className='w-full formBorder border-gray-500 rounded-md py-2 px-3 focus:bg-blue-100 focus:outline-none' name='company' defaultValue={this.state.company} onChange={this.handleInput} />
                                <Error error={this.state.errors["company"] ? this.state.errors["company"] : null} />
                            </div>
                        </div>

                        <div className='mt-4'>
                            <label className='primaryColor biomelight font-bold'>Business Address<span className='text-red-400'>*</span></label>
                            <div>
                                <input className='w-full formBorder border-gray-500 rounded-md py-2 px-3 focus:bg-blue-100 focus:outline-none' name='address' defaultValue={this.state.address} onChange={this.handleInput} />
                                <Error error={this.state.errors["address"] ? this.state.errors["address"] : null} />
                            </div>
                        </div>

                        <div className='mt-4 grid grid-cols-12 md:gap-10'>
                            <div className='md:col-span-6 col-span-12'>
                                <label className='text-gray-600 biomelight font-medium'>Address Line 2</label>
                                <div>
                                    <input className='w-full formBorder border-gray-500 rounded-md py-2 px-3 focus:bg-blue-100 focus:outline-none' name='address2' defaultValue={this.state.address2} onChange={this.handleInput} />
                                    <Error error={this.state.errors["address2"] ? this.state.errors["address2"] : null} />
                                </div>
                            </div>
                            <div className='md:col-span-6 col-span-12'>
                                <label className='text-gray-600 biomelight font-medium'>State / Province / Region</label>
                                <div>
                                    <input className='w-full formBorder border-gray-500 rounded-md py-2 px-3 focus:bg-blue-100 focus:outline-none' name='state' defaultValue={this.state.state} onChange={this.handleInput} />
                                    <Error error={this.state.errors["state"] ? this.state.errors["state"] : null} />
                                </div>
                            </div>
                        </div>

                        <div className='mt-4 grid grid-cols-12 md:gap-10'>
                            <div className='md:col-span-6 col-span-12'>
                                <label className='text-gray-600 biomelight font-medium'>City</label>
                                <div>
                                    <input className='w-full formBorder border-gray-500 rounded-md py-2 px-3 focus:bg-blue-100 focus:outline-none' name='city' defaultValue={this.state.city} onChange={this.handleInput} />
                                    <Error error={this.state.errors["city"] ? this.state.errors["city"] : null} />
                                </div>
                            </div>

                            <div className='md:col-span-6 col-span-12'>
                                <label className='text-gray-600 biomelight font-medium'>Country</label>
                                <div>
                                    <Select options={this.state.countryList} value={this.state.country} onChange={(country) => {
                                        this.setState({ country });
                                    }} />
                                </div>
                                <Error error={this.state.errors["country"] ? this.state.errors["country"] : null} />
                            </div>

                        </div>

                        <div className='mt-4'>
                            <label className='primaryColor biomelight font-bold'>Website<span className='text-red-400'>*</span></label>
                            <div>
                                <input className='w-full formBorder border-gray-500 rounded-md py-2 px-3 focus:bg-blue-100 focus:outline-none' name='website' onChange={this.handleInput} value={this.state.website || 'https://'} placeholder='' />
                                <Error error={this.state.errors["website"] ? this.state.errors["website"] : null} />
                            </div>
                        </div>


                        <div className='mt-4'>
                            <div className='grid grid-cols-12'>
                                <div className='col-span-8'>
                                    <div className='md:float-right'>
                                        {/* <button className='primaryBg biomelight font-bold text-white px-5 py-3 rounded-full'>SAVE AND CONTINUE</button> */}
                                    </div>
                                </div>

                                <div className='col-span-4'>
                                    <div className='md:float-right'>
                                        {
                                            this.state.isLoading ?
                                                <button className='bg-black biomelight font-bold text-white px-3 py-2 rounded-full text-sm'>  LOADING.... </button>
                                                :
                                                <button onClick={this.validateSubmitStep1} className='primaryBg biomelight font-bold text-white px-3 py-2 rounded-full text-sm'>NEXT</button>
                                        }

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </>
            );


        }

        if (step === 2) {

            return (
                <div>
                    <div className='mt-4'>
                        <label className='primaryColor biomelight font-bold'>Country of incorporation <span className='text-red-400'>*</span></label>
                        {/* <input type='text' className='w-full formBorder border-gray-500 rounded-md py-2 px-3 focus:bg-blue-100 focus:outline-none' name='incorporation_country' /> */}

                        <Select options={this.state.countryList} defaultInputValue={this.state.incorporation_country} value={incorporation_country} onChange={(incorporation_country) => {
                            this.setState({ incorporation_country });
                        }} />
                        <Error error={this.state.errors["incorporation_country"] ? this.state.errors["incorporation_country"] : null} />
                    </div>


                    <div className='grid grid-cols-12'>
                        <div className='mt-4 col-span-6'>
                            <label className='primaryColor biomelight font-bold'>Year of Incorporation <span className='text-red-400'>*</span></label>
                            <input type='date' className='w-full formBorder border-gray-500 rounded-md py-2 px-3 focus:bg-blue-100 focus:outline-none' name='incorporation_year' defaultValue={this.state.incorporation_year} onChange={this.handleInput} />
                            <Error error={this.state.errors["incorporation_year"] ? this.state.errors["incorporation_year"] : null} />
                        </div>

                    </div>

                    <div className='grid grid-cols-12'>
                        <div className='mt-4 col-span-6'>
                            <label className='primaryColor f-Varela font-bold'>Number of Founders <span className='text-red-400'>*</span></label>
                            <input type='number' min={"1"} max={"5"} onKeyUp={(el) => { if (el.target.value < 0) el.target.value = 0; }} className='w-full formBorder border-gray-500 rounded-md py-2 px-3 focus:bg-blue-100 focus:outline-none' name='number_founders' value={this.state.number_founders} onChange={this.handleInput} />
                            <Error error={this.state.errors["number_founders"] ? this.state.errors["number_founders"] : null} />
                        </div>
                    </div>

                    <div className='grid grid-cols-12 gap-6'>
                        {
                            Array.from({ length: this.state.number_founders }, (_, index) => (
                                // The first founder does not initially display because the data is fetched before the display so it registers as undefined even after the data is fetched.
                                <Founder key={index} count={index + 1} errors={this.state.errors?.founders?.[index] || {}} onChange={(data, info) => this.updateDynamicInput(data, info, "founders")} defaultValues={this.state.founders.length > index ? this.state.founders[index] : {}} />
                            ))
                        }
                    </div>

                    <div className='mt-4'>
                        <label className='primaryColor f-Varela font-bold'>CEO Name <span className='text-red-400'>*</span></label>
                        <input type='text' className='w-full formBorder border-gray-500 rounded-md py-2 px-3 focus:bg-blue-100 focus:outline-none' name='ceo_name' defaultValue={this.state.ceo_name} onChange={this.handleInput} />
                        <Error error={this.state.errors["ceo_name"] ? this.state.errors["ceo_name"] : null} />
                    </div>

                    <div className='mt-4'>
                        <label className='primaryColor f-Varela font-bold'>Gender <span className='text-red-400'>*</span></label>
                        <input type='text' className='w-full formBorder border-gray-500 rounded-md py-2 px-3 focus:bg-blue-100 focus:outline-none' name='ceo_gender' defaultValue={this.state.ceo_gender} onChange={this.handleInput} />
                        <Error error={this.state.errors["ceo_gender"] ? this.state.errors["ceo_gender"] : null} />
                    </div>

                    <div className='mt-4'>
                        <label className='primaryColor f-Varela font-bold'>CEO Email <span className='text-red-400'>*</span></label>
                        <input type='text' className='w-full formBorder border-gray-500 rounded-md py-2 px-3 focus:bg-blue-100 focus:outline-none' name='ceo_email' defaultValue={this.state.ceo_email} onChange={this.handleInput} />
                        <Error error={this.state.errors["ceo_email"] ? this.state.errors["ceo_email"] : null} />
                    </div>

                    <div className='mt-4'>
                        <label className='primaryColor f-Varela font-bold'>Phone Detail <span className='text-red-400'>*</span></label>
                        <input type='text' className='w-full formBorder border-gray-500 rounded-md py-2 px-3 focus:bg-blue-100 focus:outline-none' name='ceo_phone' defaultValue={this.state.ceo_phone} onChange={this.handleInput} />
                        <Error error={this.state.errors["ceo_phone"] ? this.state.errors["ceo_phone"] : null} />
                        <div className='biomelight text-gray-600'>
                            Note: Remove any symbol
                        </div>
                    </div>




                    <div className='mt-5'>
                        <div className='grid grid-cols-12'>

                            <div className='col-span-4'>
                                <div className='md:float-left'>
                                    <button onClick={this.prevStep} className='primaryBg biomelight font-bold text-white px-3 py-2 rounded-full text-sm'>Previous</button>
                                </div>
                            </div>

                            <div className='col-span-4'>
                                <div className='md:float-right'>
                                    {/* <button className='primaryBg biomelight font-bold text-white px-5 py-3 rounded-full'>SAVE AND CONTINUE</button> */}
                                </div>
                            </div>

                            <div className='col-span-4'>
                                <div className='md:float-right'>
                                    {
                                        this.state.isLoading ?
                                            <button className='bg-black biomelight font-bold text-white px-3 py-2 rounded-full text-sm'>
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    className="mr-1"
                                                /> LOADING....
                                            </button>
                                            :
                                            <button onClick={this.validateSubmitStep2} className='primaryBg biomelight font-bold text-white px-3 py-2 rounded-full text-sm'>Next</button>
                                    }
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            );
        }


        if (step === 3) {


            return (
                <div>

                    <div className='grid grid-cols-12 gap-6'>
                        <div className='mt-4 md:col-span-6 col-span-12'>
                            <label className='primaryColor f-Varela font-bold'>Founder’s equity share <span className='text-red-400'>*</span></label>
                            <select className='w-full formBorder border-gray-500 rounded-md py-2 px-3 focus:bg-blue-100 focus:outline-none' name='founder_equity' defaultValue={this.state.founder_equity} onChange={this.handleInput}>
                                <option value="">Select a percent range</option>
                                <option value="1 - 20%">1 - 20%</option>
                                <option value="21 - 30%">21 - 30%</option>
                                <option value="31 - 40%">31 - 40%</option>
                                <option value="41 - 50%">41 - 50%</option>
                                <option value="51 - 60%">51 - 60%</option>
                                <option value="61 - 70%">61 - 70%</option>
                                <option value="71 - 80%">71 - 80%</option>
                                <option value="81 - 90%">81 - 90%</option>
                                <option value="90 - 100%">90 - 100%</option>
                            </select>
                            <Error error={this.state.errors["founder_equity"] ? this.state.errors["founder_equity"] : null} />
                        </div>

                        <div className='mt-4 md:col-span-6 col-span-12'>
                            <label className='primaryColor f-Varela font-bold'>Employee Size <span className='text-red-400'>*</span></label>
                            <input type='number' className='w-full formBorder border-gray-500 rounded-md py-2 px-3 focus:bg-blue-100 focus:outline-none' name='employee_size' defaultValue={this.state.employee_size} onChange={this.handleInput} />
                            <Error error={this.state.errors["employee_size"] ? this.state.errors["employee_size"] : null} />
                        </div>

                    </div>



                    <div className='grid grid-cols-12'>
                        <div className='mt-4 md:col-span-6 col-span-12'>
                            <label className='primaryColor f-Varela font-bold'>Number of Member(s) <span className='text-red-400'>*</span></label>
                            <input type='number' min={"1"} max={"5"} onKeyUp={(el) => { if (el.value < 0) el.value = 0; }} className='w-full formBorder border-gray-500 rounded-md py-2 px-3 focus:bg-blue-100 focus:outline-none' name='key_member' value={this.state.key_member} onChange={this.handleInput} />
                            <Error error={this.state.errors["key_member"] ? this.state.errors["key_member"] : null} />
                        </div>
                    </div>

                    <div className='grid grid-cols-12 gap-6'>
                        {
                            Array.from({ length: this.state.key_member }, (_, index) => (
                                <Member key={index} count={index + 1} errors={this.state.errors?.members?.[index] || {}} onChange={(data, info) => this.updateDynamicInput(data, info, "members")} />
                            ))
                        }
                    </div>

                    <div className='mt-4'>
                        <label className='primaryColor f-Varela font-bold'>Current Business Stage <span className='text-red-400'>*</span></label>
                        <select className='w-full formBorder border-gray-500 rounded-md py-2 px-3 focus:bg-blue-100 focus:outline-none' name='business_stage' onChange={this.handleInput} defaultValue={this.state.business_stage}>
                            <option value="">Select your business stage</option>
                            <option value="Bootstrapping">Bootstrapping</option>
                            <option value="Angel stage">Angel stage</option>
                            <option value="Pre-seed stage">Pre-seed stage</option>
                            <option value="Seed stage">Seed stage</option>
                            <option value="Startup pre-revenue">Startup pre-revenue</option>
                            <option value="Startup post-revenue">Startup post-revenue</option>
                        </select>
                        <Error error={this.state.errors["business_stage"] ? this.state.errors["business_stage"] : null} />
                    </div>

                    <div className='mt-4'>
                        <label className='primaryColor biomelight font-bold'>Fundraising Round<span className='text-red-400'>*</span></label>
                        <select className='w-full formBorder border-gray-500 rounded-md py-2 px-3 focus:bg-blue-100 focus:outline-none' name='fundraising_round' onChange={this.handleInput}>
                            <option value="">Select an appropriate option</option>
                            <option value="Pre-seed fundraise">Pre-seed fundraise</option>
                            <option value="Seed fundraise">Seed fundraise</option>
                            <option value="Other">Other</option>
                        </select>
                        <Error error={this.state.errors["fundraising_round"] ? this.state.errors["fundraising_round"] : null} />
                    </div>





                    <div className='mt-5'>
                        <div className='grid grid-cols-12'>

                            <div className='col-span-4'>
                                <div className='md:float-left'>
                                    <button onClick={this.prevStep} className='primaryBg biomelight font-bold text-white px-3 py-2 rounded-full text-sm'>Previous</button>
                                </div>
                            </div>

                            <div className='col-span-4'>
                                <div className='md:float-right'>
                                    {/* <button className='primaryBg biomelight font-bold text-white px-5 py-3 rounded-full'>SAVE AND CONTINUE</button> */}
                                </div>
                            </div>

                            <div className='col-span-4'>
                                <div className='md:float-right'>
                                    {
                                        this.state.isLoading ?
                                            <button className='bg-black biomelight font-bold text-white px-3 py-2 rounded-full text-sm'>
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    className="mr-1"
                                                /> LOADING....
                                            </button>
                                            :
                                            <button onClick={this.validateSubmitStep3} className='primaryBg biomelight font-bold text-white px-3 py-2 rounded-full text-sm'>Next</button>
                                    }
                                </div>
                            </div>
                        </div>

                    </div>



                </div>



            );
        }


        if (step === 4) {


            return (
                <div>

                    <div className='mt-4 md:col-span-6 col-span-12'>
                        <label className='primaryColor f-Varela font-bold'>Investment Ask <span className='text-red-400'>*</span></label>
                        <input type='text' className='w-full formBorder border-gray-500 rounded-md py-2 px-3 focus:bg-blue-100 focus:outline-none' name='investment_ask' defaultValue={this.state.investment_ask} onChange={this.handleInput} />
                        <Error error={this.state.errors["investment_ask"] ? this.state.errors["investment_ask"] : null} />
                    </div>

                    <div className='mt-4 md:col-span-6 col-span-12'>
                        <label className='primaryColor f-Varela font-bold'>Currency<span className='text-red-400'>*</span></label>
                        <select className='w-full formBorder border-gray-500 rounded-md py-2 px-3 focus:bg-blue-100 focus:outline-none' name='currency' defaultValue={this.state.currency} onChange={this.handleInput}>
                            <option value="">Select a currency</option>
                            <option value="Pounds">Pounds</option>
                            <option value="Dollar">Dollars</option>
                            <option value="Euros">Euros</option>
                        </select>
                        <Error error={this.state.errors["currency"] ? this.state.errors["currency"] : null} />
                    </div>

                    <div className='mt-4 md:col-span-6 col-span-12'>
                        <label className='primaryColor f-Varela font-bold'>Estimated company valuation  <span className='text-red-400'>*</span></label>
                        <input type='text' className='w-full formBorder border-gray-500 rounded-md py-2 px-3 focus:bg-blue-100 focus:outline-none' name='company_valuation' defaultValue={this.state.company_valuation} onChange={this.handleInput} />
                        <Error error={this.state.errors["company_valuation"] ? this.state.errors["company_valuation"] : null} />
                    </div>

                    <div className='mt-4 md:col-span-6 col-span-12'>
                        <label className='primaryColor biomelight font-bold'>Revenue streams  <span className='text-red-400'>*</span></label>

                        <div className="checkbox-wrapper-1 text-gray-700 biomelight">
                            <input id="example-1" name='revenue_streams' value="Fixed revenue" className="substituted" type="checkbox" aria-hidden="true" onChange={this.handleInput} />
                            <label htmlFor="example-1" className='pt-0'>Fixed revenue</label>
                        </div>

                        <div className="checkbox-wrapper-1 text-gray-700 biomelight">
                            <input id="example-2" name='revenue_streams' value="Subscription-based revenue" className="substituted" type="checkbox" aria-hidden="true" onChange={this.handleInput} />
                            <label htmlFor="example-2" className='pt-0'>Subscription-based revenue</label>
                        </div>

                        <div className="checkbox-wrapper-1 text-gray-700 biomelight">
                            <input id="example-3" name='revenue_streams' value="Transactional Revenue Model" className="substituted" type="checkbox" aria-hidden="true" onChange={this.handleInput} />
                            <label htmlFor="example-3" className='pt-0'>Transactional Revenue Model</label>
                        </div>

                        <div className="checkbox-wrapper-1 text-gray-700 biomelight">
                            <input id="example-4" name='revenue_streams' value="Channel Sales (or Indirect Sales)" className="substituted" type="checkbox" aria-hidden="true" onChange={this.handleInput} />
                            <label htmlFor="example-4" className='pt-0'>Channel Sales (or Indirect Sales)</label>
                        </div>

                        <div className="checkbox-wrapper-1 text-gray-700 biomelight">
                            <input id="example-5" name='revenue_streams' value="Ad-Based Revenue Model" className="substituted" type="checkbox" aria-hidden="true" onChange={this.handleInput} />
                            <label htmlFor="example-5" className='pt-0'>Ad-Based Revenue Model</label>
                        </div>

                        <div className="checkbox-wrapper-1 text-gray-700 biomelight">
                            <input id="example-6" name='revenue_streams' value="Direct Sales" className="substituted" type="checkbox" aria-hidden="true" onChange={this.handleInput} />
                            <label htmlFor="example-6" className='pt-0'>Direct Sales</label>
                        </div>

                        <div className="checkbox-wrapper-1 text-gray-700 biomelight">
                            <input id="example-7" name='revenue_streams' value="Other" className="substituted" type="checkbox" aria-hidden="true" onChange={this.handleInput} />
                            <label htmlFor="example-7" className='pt-0'>Other</label>
                        </div>

                        <Error error={this.state.errors["revenue_streams"] ? this.state.errors["revenue_streams"] : null} />

                    </div>


                    {
                        Array.isArray(this.state.revenue_streams) && this.state.revenue_streams.includes("Other") ? (
                            <div className='mt-4'>
                                <label className='primaryColor f-Varela font-bold'>If other, please state.  <span className='text-red-400'>*</span></label>
                                <input type='text' className='w-full formBorder border-gray-500 rounded-md py-2 px-3 focus:bg-blue-100 focus:outline-none' name='other_revenue_stream' defaultValue={this.state.other_revenue_stream} onChange={this.handleInput} />
                                <Error error={this.state.errors["other_revenue_stream"] ? this.state.errors["other_revenue_stream"] : null} />
                            </div>
                        ) : ""
                    }


                    <div className='mt-4'>
                        <label className='primaryColor biomelight font-bold'>Is the business generating any revenue?   <span className='text-red-400'>*</span></label>

                        <div className='mt-0 md:col-span-6 col-span-12'>
                            <div className=''>
                                <label htmlFor="f-revenue" className="l-radio">
                                    <input type="radio" id="f-revenue" name="is_revenue" value={1} tabIndex="1" onChange={this.handleInput} checked={this.state.is_revenue === "1"} />
                                    <span>Yes</span>
                                </label>
                            </div>
                            <div className=''>
                                <label htmlFor="s-revenue" className="l-radio">
                                    <input type="radio" id="s-revenue" name="is_revenue" value={0} tabIndex="2" onChange={this.handleInput} checked={this.state.is_revenue === "0"} />
                                    <span>No</span>
                                </label>
                            </div>

                            <Error error={this.state.errors["is_revenue"] ? this.state.errors["is_revenue"] : null} />
                        </div>

                    </div>

                    {
                        this.state.is_revenue === "1" ? (
                            <div className='mt-4 md:col-span-6 col-span-12'>
                                <label className='primaryColor f-Varela font-bold'>What is the business’s Monthly revenue?  <span className='text-red-400'>*</span></label>
                                <select className='w-full formBorder border-gray-500 rounded-md py-2 px-3 focus:bg-blue-100 focus:outline-none' name='monthly_revenue' defaultValue={this.state.monthly_revenue} onChange={this.handleInput}>
                                    <option value="">Select a revenue range</option>
                                    <option value="$0 - $1,000">$0 - $1,000</option>
                                    <option value="$1,000 - $10,000">$1,000 - $10,000</option>
                                    <option value="$10,000 - $50,000">$10,000 - $50,000</option>
                                    <option value="$50,000 - $100,000">$10,000 - $50,000</option>
                                    <option value="$100,000+">Above $100,000</option>
                                </select>

                                <Error error={this.state.errors["monthly_revenue"] ? this.state.errors["monthly_revenue"] : null} />
                            </div>
                        ) : ""
                    }

                    <div className='mt-4 md:col-span-6 col-span-12'>
                        <label className='primaryColor f-Varela font-bold'>How does the product make money? <span className='text-red-400'>*</span></label>
                        <textarea rows={5} className='w-full formBorder border-gray-500 rounded-md py-2 px-3 focus:bg-blue-100 focus:outline-none' name='how_revenue' defaultValue={this.state.how_revenue} onChange={this.handleInput}></textarea>
                        <Error error={this.state.errors["how_revenue"] ? this.state.errors["how_revenue"] : null} />
                    </div>



                    <div className='mt-4'>
                        <label className='primaryColor biomelight font-bold'> Accelerator programs?  <span className='text-red-400'>*</span></label>

                        <div className='mt-1'>
                            <label htmlFor="f-accelerator" className="l-radio">
                                <input type="radio" id="f-accelerator" name="accelerator_program" value={1} tabIndex="1" onChange={this.handleInput} checked={this.state.accelerator_program === "1"} />
                                <span>Yes</span>
                            </label>

                            <label htmlFor="s-accelerator" className="l-radio">
                                <input type="radio" id="s-accelerator" name="accelerator_program" value={0} tabIndex="2" onChange={this.handleInput} checked={this.state.accelerator_program === "0"} />
                                <span>No</span>
                            </label>

                            <Error error={this.state.errors["accelerator_program"] ? this.state.errors["accelerator_program"] : null} />
                        </div>

                    </div>

                    {
                        this.state.accelerator_program === "1" ? (
                            <div className='mt-4'>
                                <label className='primaryColor f-Varela font-bold'>If yes, include the name of the accelerator program and the year(s) attended.  <span className='text-red-400'>*</span></label>
                                <input type='text' className='w-full formBorder border-gray-500 rounded-md py-2 px-3 focus:bg-blue-100 focus:outline-none' name='accelerator_attended' defaultValue={this.state.accelerator_attended} onChange={this.handleInput} />
                                <Error error={this.state.errors["accelerator_attended"] ? this.state.errors["accelerator_attended"] : null} />
                            </div>
                        ) : ""
                    }



                    <div className='mt-5'>
                        <div className='grid grid-cols-12'>

                            <div className='col-span-4'>
                                <div className='md:float-left'>
                                    <button onClick={this.prevStep} className='primaryBg biomelight font-bold text-white px-3 py-2 rounded-full text-sm'>Previous</button>
                                </div>
                            </div>

                            <div className='col-span-4'>
                                <div className='md:float-right'>
                                    {/* <button className='primaryBg biomelight font-bold text-white px-5 py-3 rounded-full'>SAVE AND CONTINUE</button> */}
                                </div>
                            </div>

                            <div className='col-span-4'>
                                <div className='md:float-right'>


                                    {
                                        this.state.isLoading ?
                                            <button className='bg-black biomelight font-bold text-white px-3 py-2 rounded-full text-sm'>
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    className="mr-1"
                                                /> LOADING....
                                            </button>
                                            :

                                            <button onClick={this.validateSubmitStep4} className='primaryBg biomelight font-bold text-white px-3 py-2 rounded-full text-sm'>Next</button>
                                    }

                                </div>
                            </div>
                        </div>

                    </div>


                </div>


            );


        }

        if (step === 5) {

            return (
                <div className=''>
                    <div className=''>
                        <div className='mt-4'>
                            <label className='primaryColor biomelight font-bold'>Product type   <span className='text-red-400'>*</span></label>

                            <div className='mt-0 md:col-span-6 col-span-12'>
                                <div className=''>
                                    <label htmlFor="f-ptype" className="l-radio">
                                        <input type="radio" id="f-ptype" name="product_type" value={'New Innovation'} onChange={this.handleInput} checked={this.state?.product_type === "New Innovation"} />
                                        <span>New Innovation</span>
                                    </label>
                                </div>
                                <div className=''>
                                    <label htmlFor="s-ptypex" className="l-radio">
                                        <input type="radio" id="s-ptypex" name="product_type" value={'Improvement to Existing Innovation'} onChange={this.handleInput} checked={this.state?.product_type === "Improvement to Existing Innovation"} />
                                        <span>Improvement to Existing Innovation</span>
                                    </label>
                                </div>

                                <Error error={this.state.errors["product_type"] ? this.state.errors["product_type"] : null} />
                            </div>
                        </div>

                        <div className='mt-4'>
                            <h6 className='primaryColor biomelight font-bold'>Who are your competitors?<span className='text-red-400'>*</span></h6>

                            <div className='grid-cols-12 grid gap-5'>
                                <div className='md:col-span-6 col-span-12'>
                                    <div className='mt-4'>
                                        <label className='primaryColor f-Varela font-bold'>First Competitor  <span className='text-red-400'>*</span></label>
                                        <input type='text' className='w-full formBorder border-gray-500 rounded-md py-2 px-3 focus:bg-blue-100 focus:outline-none' name='competitor' defaultValue={this.state.competitor} onChange={this.handleInput} />
                                    </div>
                                    <Error error={this.state.errors["competitor"] ? this.state.errors["competitor"] : null} />
                                </div>
                                <div className='md:col-span-6 col-span-12'>
                                    <div className='mt-4'>
                                        <label className='primaryColor f-Varela font-bold'>First Competitor Website  <span className='text-red-400'>*</span></label>
                                        <input type='text' className='w-full formBorder border-gray-500 rounded-md py-2 px-3 focus:bg-blue-100 focus:outline-none' name='competitor_website' defaultValue={this.state.competitor_website} onChange={this.handleInput} />
                                    </div>
                                    <Error error={this.state.errors["competitor_website"] ? this.state.errors["competitor_website"] : null} />
                                </div>

                                <div className='md:col-span-6 col-span-12'>
                                    <div className='mt-2'>
                                        <label className='primaryColor f-Varela font-bold'>Second Competitor  <span className='text-red-400'>*</span></label>
                                        <input type='text' className='w-full formBorder border-gray-500 rounded-md py-2 px-3 focus:bg-blue-100 focus:outline-none' name='competitor2' defaultValue={this.state.competitor2} onChange={this.handleInput} />
                                    </div>
                                    <Error error={this.state.errors["competitor2"] ? this.state.errors["competitor2"] : null} />
                                </div>
                                <div className='md:col-span-6 col-span-12'>
                                    <div className='mt-2'>
                                        <label className='primaryColor f-Varela font-bold'>Second Competitor Website  <span className='text-red-400'>*</span></label>
                                        <input type='text' className='w-full formBorder border-gray-500 rounded-md py-2 px-3 focus:bg-blue-100 focus:outline-none' name='competitor2_website' defaultValue={this.state.competitor2_website} onChange={this.handleInput} />
                                    </div>
                                    <Error error={this.state.errors["competitor2_website"] ? this.state.errors["competitor2_website"] : null} />
                                </div>
                            </div>
                        </div>


                        <div className='mt-4'>
                            <label className='primaryColor biomelight font-bold'>Does the business require a regulatory license?   <span className='text-red-400'>*</span></label>
                            <div className='mt-0 md:col-span-6 col-span-12'>
                                <label htmlFor="f-license" className="l-radio">
                                    <input type="radio" id="f-license" name="regulatory_license" value={1} tabIndex="1" onChange={this.handleInput} checked={this.state.regulatory_license === "1"} />
                                    <span>Yes</span>
                                </label>

                                <label htmlFor="s-license" className="l-radio">
                                    <input type="radio" id="s-license" name="regulatory_license" value={0} tabIndex="2" onChange={this.handleInput} checked={this.state.regulatory_license === "0"} />
                                    <span>No</span>
                                </label>
                            </div>
                            <Error error={this.state.errors["regulatory_license"] ? this.state.errors["regulatory_license"] : null} />
                        </div>

                        <div className='mt-4 md:col-span-6 col-span-12'>
                            <label className='primaryColor f-Varela font-bold'>What type of funding is required? <span className='text-red-400'>*</span></label>
                            <select className='w-full formBorder border-gray-500 rounded-md py-2 px-3 focus:bg-blue-100 focus:outline-none' name='type_funding' value={this.state.type_funding} onChange={this.handleInput}>
                                <option value="">Select Funding Type</option>
                                <option value="Equity">Equity</option>
                                <option value="Debt">Debt</option>
                            </select>
                            <Error error={this.state.errors["type_funding"] ? this.state.errors["type_funding"] : null} />
                        </div>

                        <div className='mt-4'>
                            <label className='primaryColor biomelight font-bold'>Has the business raised any capital?   <span className='text-red-400'>*</span></label>
                            <div className='mt-0 md:col-span-6 col-span-12'>
                                <label htmlFor="f-capital" className="l-radio">
                                    <input type="radio" id="f-capital" name="capital_raise" value={1} tabIndex="1" onChange={this.handleInput} checked={this.state.capital_raise === "1"} />
                                    <span>Yes</span>
                                </label>

                                <label htmlFor="s-capital" className="l-radio">
                                    <input type="radio" id="s-capital" name="capital_raise" value={0} tabIndex="2" onChange={this.handleInput} checked={this.state.capital_raise === "0"} />
                                    <span>No</span>
                                </label>
                            </div>
                            <Error error={this.state.errors["capital_raise"] ? this.state.errors["capital_raise"] : null} />
                        </div>

                        <div className='mt-4'>
                            <label className='primaryColor biomelight font-bold'>Has the business issued stocks to investors?  <span className='text-red-400'>*</span></label>
                            <div className='mt-0 md:col-span-6 col-span-12'>
                                <label htmlFor="f-investor" className="l-radio">
                                    <input type="radio" id="f-investor" name="issued_investor_stock" value={1} tabIndex="1" onChange={this.handleInput} checked={this.state.issued_investor_stock === "1"} />
                                    <span>Yes</span>
                                </label>

                                <label htmlFor="s-investor" className="l-radio">
                                    <input type="radio" id="s-investor" name="issued_investor_stock" value={0} tabIndex="2" onChange={this.handleInput} checked={this.state.issued_investor_stock === "0"} />
                                    <span>No</span>
                                </label>
                            </div>
                            <Error error={this.state.errors["issued_investor_stock"] ? this.state.errors["issued_investor_stock"] : null} />
                        </div>

                        <div className='mt-4'>
                            <label className='primaryColor biomelight font-bold'>Has the company received any government grant(s)?  <span className='text-red-400'>*</span></label>
                            <div className='mt-0 md:col-span-6 col-span-12'>
                                <label htmlFor="f-grant" className="l-radio">
                                    <input type="radio" id="f-grant" name="goverment_grant" value={1} tabIndex="1" onChange={this.handleInput} checked={this.state.goverment_grant === "1"} />
                                    <span>Yes</span>
                                </label>

                                <label htmlFor="s-grant" className="l-radio">
                                    <input type="radio" id="s-grant" name="goverment_grant" value={0} tabIndex="2" onChange={this.handleInput} checked={this.state.goverment_grant === "0"} />
                                    <span>No</span>
                                </label>
                            </div>
                            <Error error={this.state.errors["goverment_grant"] ? this.state.errors["goverment_grant"] : null} />
                        </div>


                        {
                            this.state.goverment_grant === "1" ? (
                                <div className='mt-4 md:col-span-6 col-span-12'>
                                    <label className='primaryColor f-Varela font-bold'>Additional Information on the government grant you received <span className='text-red-400'>*</span></label>
                                    <textarea rows={5} className='w-full formBorder border-gray-500 rounded-md py-2 px-3 focus:bg-blue-100 focus:outline-none' name='goverment_grant_information' defaultValue={this.state.goverment_grant_information} onChange={this.handleInput}></textarea>
                                    <Error error={this.state.errors["goverment_grant_information"] ? this.state.errors["goverment_grant_information"] : null} />
                                </div>
                            ) : ""
                        }


                        <div className='mt-4'>
                            <label className='primaryColor biomelight font-bold'>Would you like to schedule a meeting after submitting the form?  <span className='text-red-400'>*</span></label>
                            <div className='mt-0 md:col-span-6 col-span-12'>
                                <label htmlFor="f-meeting" className="l-radio">
                                    <input type="radio" id="f-meeting" name="meeting" value={1} tabIndex="1" onChange={this.handleInput} checked={this.state.meeting === "1"} />
                                    <span>Yes</span>
                                </label>

                                <label htmlFor="s-meeting" className="l-radio">
                                    <input type="radio" id="s-meeting" name="meeting" value={0} tabIndex="2" onChange={this.handleInput} checked={this.state.meeting === "0"} />
                                    <span>No</span>
                                </label>
                            </div>
                            <Error error={this.state.errors["meeting"] ? this.state.errors["meeting"] : null} />
                        </div>


                        <div className='mt-4 md:col-span-6 col-span-12'>
                            <label className='primaryColor f-Varela font-bold'>Additional Comments <span className='text-red-400'>*</span></label>
                            <textarea rows={5} className='w-full formBorder border-gray-500 rounded-md py-2 px-3 focus:bg-blue-100 focus:outline-none' name='comment' defaultValue={this.state.comment} onChange={this.handleInput}></textarea>
                            <Error error={this.state.errors["comment"] ? this.state.errors["comment"] : null} />
                        </div>






                        <div className='mt-5'>
                            <div className='grid grid-cols-12'>

                                <div className='col-span-4'>
                                    <div className='md:float-left'>
                                        <button onClick={this.prevStep} className='primaryBg biomelight font-bold text-white px-3 py-2 rounded-full text-sm'>Previous</button>
                                    </div>
                                </div>

                                <div className='col-span-4'>
                                    <div className='md:float-right'>
                                        {/* <button className='primaryBg biomelight font-bold text-white px-5 py-3 rounded-full'>SAVE AND CONTINUE</button> */}
                                    </div>
                                </div>

                                <div className='col-span-4'>
                                    <div className='md:float-right'>

                                        {
                                            this.state.isLoading ?
                                                <button className='bg-black biomelight font-bold text-white px-3 py-2 rounded-full text-sm'>
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                        className="mr-1"
                                                    /> LOADING....
                                                </button>
                                                :
                                                <>
                                                    <button onClick={this.validateSubmitStep5} className='primaryBg biomelight font-bold text-white px-5 py-3 rounded-full text-md'> Submit </button>
                                                    {/* <button onClick={this.redirectHome} className='primaryBg biomelight font-bold text-white px-5 py-3 rounded-full text-md'> Submit </button> */}
                                                </>
                                        }

                                    </div>
                                </div>
                            </div>

                        </div>




                    </div>
                </div>
            );
        }




    }



    render() {

        return (
            <>
                <div className='border-bottom'>
                    <NavBar />
                </div>
                <section className='mt-5'>

                    <div className='container mx-auto mb-20'>
                        <div className='my-5  mb-10'>
                            <h2 className='biomeBold font-extrabold text-center primaryColor'>CDC Form</h2>
                            <p className='biomeBold text-center mb-5'>If you encounter any problems, pls send a mail to <a href="mail: info@ce-iv.com"> info@ce-iv.com </a></p>
                        </div>

                        <div className='mt-5'>
                            <div className="mt-5">
                                <ul className="progress-indicator mb-3 mt-2 pb-3">
                                    <li className="completed" style={{ marginLeft: '0px' }}>

                                        {this.state.step >= 1 ? (
                                            <span className="bubble completedInnerx">
                                                <span className="innerBubble completedInner">
                                                    <FeatherIcon
                                                        icon={"check"}
                                                        size={23}
                                                        className="inline-block"
                                                    />
                                                </span>
                                            </span>
                                        ) : (
                                            <span className="bubble biomeBold">
                                                <span className="innerBubble">1</span>
                                            </span>
                                        )}

                                        <span className="stacked-text biomeBold font-bold">
                                            Step 1
                                        </span>
                                    </li>

                                    <li className="completed">

                                        {this.state.step >= 2 ? (
                                            <span className="bubble completedInnerx">
                                                <span className="innerBubble completedInner">
                                                    <FeatherIcon
                                                        icon={"check"}
                                                        size={23}
                                                        className="inline-block"
                                                    />
                                                </span>
                                            </span>
                                        ) : (
                                            <span className="bubble biomeBold">
                                                <span className="innerBubble">2</span>
                                            </span>
                                        )}

                                        <span className="stacked-text biomeBold font-bold">
                                            Step 2
                                        </span>
                                    </li>

                                    <li className="completed">

                                        {this.state.step >= 3 ? (
                                            <span className="bubble completedInnerx">
                                                <span className="innerBubble completedInner">
                                                    <FeatherIcon
                                                        icon={"check"}
                                                        size={23}
                                                        className="inline-block"
                                                    />
                                                </span>
                                            </span>
                                        ) : (
                                            <span className="bubble">
                                                <span className="innerBubble biomeBold">3</span>
                                            </span>
                                        )}

                                        <span className="stacked-text biomeBold font-bold">
                                            Step 3
                                        </span>
                                    </li>

                                    <li className="completed">

                                        {this.state.step >= 4 ? (
                                            <span className="bubble completedInnerx">
                                                <span className="innerBubble completedInner">
                                                    <FeatherIcon
                                                        icon={"check"}
                                                        size={23}
                                                        className="inline-block"
                                                    />
                                                </span>
                                            </span>
                                        ) : (
                                            <span className="bubble">
                                                <span className="innerBubble biomeBold">4</span>
                                            </span>
                                        )}

                                        <span className="stacked-text biomeBold font-bold">
                                            Step 4
                                        </span>
                                    </li>

                                    <li className="completed">

                                        {this.state.step >= 5 ? (
                                            <span className="bubble completedInnerx">
                                                <span className="innerBubble completedInner">
                                                    <FeatherIcon
                                                        icon={"check"}
                                                        size={23}
                                                        className="inline-block"
                                                    />
                                                </span>
                                            </span>
                                        ) : (
                                            <span className="bubble">
                                                <span className="innerBubble biomeBold">5</span>
                                            </span>
                                        )}

                                        <span className="stacked-text biomeBold font-bold">
                                            Step 5
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className=''>
                            {this.currentStep()}
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

//export default Password


const mapDispatchToProps = dispatch => {

    return {

        setLogin: user => dispatch({ type: "SET_LOGIN", payload: user })
    }
}

export default connect(null, mapDispatchToProps)(Apply);