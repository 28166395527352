import React, { Component } from "react";
import { connect } from 'react-redux';

import Error from '../inc/Error';
import postApi from '../api_request/Post';
import { ServerLink } from '../keys/Links';
import notifyError from '../inc/SnackbarError';
import { Spinner } from 'react-bootstrap';


class Account extends Component {




    constructor(props, context) {
        super(props, context);

        this.state = {
            first: '',
            lastname: '',
            email: '',
            address: '',
            phone_code: '234',
            phone: '',
            commission: '',
            fields: {},
            errors: {},
            isLoading: false
        }

    }





    handleInput = (e) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value });
    }


    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });

        //console.log(fields)
    }




    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        //firstname
        if (!fields["firstname"] && !this.props.userInfo.firstname) {
            formIsValid = false;
            errors["firstname"] = "Firstname cannot be empty";
        }

        if (typeof fields["firstname"] !== "undefined") {
            if (!fields["firstname"].match(/^[a-zA-Z]+$/)) {
                formIsValid = false;
                errors["firstname"] = "Only letters, Field cannot be empty";
            }
        }

        //lastname
        if (!fields["lastname"] && !this.props.userInfo.lastname) {
            formIsValid = false;
            errors["lastname"] = "Lastname cannot be empty";
        }

       

        if (typeof fields["lastname"] !== "undefined") {
            if (!fields["lastname"].match(/^[a-zA-Z]+$/)) {
                formIsValid = false;
                errors["lastname"] = "Only letters, Field cannot be empty";
            }
        }


        //Email
        if (!fields["email"] && !this.props.userInfo.email) {
            formIsValid = false;
            errors["email"] = "Email cannot be empty, provide a valid mail";
        }

        if (typeof fields["email"] !== "undefined") {
            let lastAtPos = fields["email"].lastIndexOf('@');
            let lastDotPos = fields["email"].lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') == -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
                formIsValid = false;
                errors["email"] = "Email is not valid, provide a valid mail";
            }
        }





        this.setState({ errors: errors });
        return formIsValid;
    }




    onSubmit = (e) => {

        e.preventDefault();


        if (this.handleValidation()) {

            this.setState({

                isLoading: true,
            })

            //proccess the form
            let fields = this.state.fields;

            const data = {
                firstname: fields['firstname'] ?? this.props.userInfo.firstname,
                lastname: fields['lastname'] ?? this.props.userInfo.lastname,
                email: fields['email'] ?? this.props.userInfo.email,
                address: fields['address'] ?? this.props.userInfo.address,
                phone: this.props.userInfo.phone,
                //phoneCode: this.state.phone_code,
            }


            //console.log(data)

            postApi(ServerLink + 'api/update', data).then(
                res => {

                    if (res.success == true) {

                        this.setState({
                            isLoading: false,
                            errors: {
                                'error': ''
                            }
                        })

                        notifyError(res.message, 'success', 'top-center')


                    }
                    else {

                        notifyError(res.message, 'error', 'top-center');

                        this.setState({
                            isLoading: false,
                        })

                        console.log(res)
                    }
                }
            ).catch(e => {
                notifyError(e, 'error', 'top-center');

                this.setState({
                    isLoading: false,
                })

                console.log(e)
            })

        }

        else {
            console.log('Error')
        }
    }



    render() {





        return (
            <>
                <div className='md:w-8/12 mx-auto'>
                    <div className="homeBoard pb-20 shadow-lg">
                        <div className="mx-5 py-3 workSanMedium border-b">
                            <h4 className="biomeBold">Profile Details</h4>
                        </div>

                        <form className="biomelight" onSubmit={this.onSubmit}>

                            <div className="mx-5 mt-4 grid grid-cols-12 gap-4">
                                <div className="md:col-span-6 col-span-12">
                                    <label>First Name</label>
                                    <input onChange={this.handleChange.bind(this, "firstname")} name="firstname" defaultValue={this.props.userInfo.firstname} className="px-3 py-2 border w-full focus:outline-none focus:bg-green-100" />
                                    <Error error={this.state.errors['firstname'] ? this.state.errors['firstname'] : null} />
                                </div>
                                <div className="md:col-span-6 col-span-12">
                                    <label>Last Name</label>
                                    <input onChange={this.handleChange.bind(this, "lastname")} name="lastname" defaultValue={this.props.userInfo.lastname} className="px-3 py-2 border w-full focus:outline-none focus:bg-green-100" />
                                    <Error error={this.state.errors['lastname'] ? this.state.errors['lastname'] : null} />
                                </div>
                                <div className="col-span-12">
                                    <label>Phone Number</label>
                                    <input name="phone" defaultValue={this.props.userInfo.phone} className="px-3 py-2 border w-full focus:outline-none focus:bg-green-100" />
                                </div>
                                <div className="col-span-12">
                                    <label>E-mail</label>
                                    <input readOnly onChange={this.handleChange.bind(this, "email")} name="email" defaultValue={this.props.userInfo.email} className="px-3 py-2 border w-full bg-gray-300 focus:outline-none focus:bg-green-100" />
                                    <Error error={this.state.errors['email'] ? this.state.errors['email'] : null} />
                                </div>


                                <div className="col-span-12">
                                    {
                                        this.state.isLoading
                                            ?
                                            <button className="bg-black hover:bg-gray-500 text-white font-bold workSan md:w-auto w-full py-2 ">
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    className="mr-1"
                                                />
                                                Loading...
                                            </button>

                                            :
                                            <button className="primaryBg py-2 px-4 rounded-md text-white md:w-auto w-full">Update Profile</button>

                                    }
                                </div>

                            </div>

                        </form>


                    </div>
                </div>

            </>
        )
    }



}




const mapStateToProps = state => {

    return {
        loggedIn: state.auth.loggedIn,
        userInfo: state.auth.user

    }
}


const mapDispatchProps = dispatch => {

    return {
        loggout: () => dispatch({ type: 'SET_LOGOUT', })
    }
}

export default connect(mapStateToProps, mapDispatchProps)(Account);
