import { BrowserRouter, Switch, Route } from 'react-router-dom';


import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';


import './dist/css/ceiv.css';
import './dist/css/ceiv-media.css';
import './dist/css/bootstrap.min.css';


import GuestRoute from './components/GuestRoute';
import Login from './auth/Login';
import Register from './auth/Register';
import Forgot from './auth/Forgot';
import AuthRoute from './components/AuthRoute';
import Apply from './home/Apply';
import Password from './home/Password';
import Account from './home/Account';

import Landing from './pages/Landing';
import AboutPage from "./pages/About";
import TeamPage from "./pages/Team";
import PortfolioPage from "./pages/Portfolio";

import Advisory from './pages/Advisory';
import Media from './pages/MediaPage';
import Dashboard from './home/Dashboard';
import Structure from './inc/structure';
import Complete from './home/Complete';
import Utform from './home/Utform';
import UploadUBN from './home/UploadUBN';
import PointP from './home/PointP';




function App() {
  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={Landing} />
          <Route path="/about" component={AboutPage} />
          <Route path="/team" component={TeamPage} />
          <Route path="/advisory" component={Advisory} />
          <Route path="/portfolio" component={PortfolioPage} />
          <Route path="/media" component={Media} />

          <GuestRoute path="/login" component={Login} />
          <GuestRoute path="/Register" component={Register} />
          <GuestRoute path="/forgotten-password" component={Forgot} />

          <AuthRoute path="/apply" component={Apply} />
          <AuthRoute path="/apply-utform" component={Utform} />
          <AuthRoute path="/upload-ubnform" component={UploadUBN} />
          <AuthRoute path="/complete" component={Complete} />
          

          <Structure>
            <AuthRoute path="/update-password" component={Password} />
            <AuthRoute path="/account" component={Account} />
            <AuthRoute path="/pointp" component={PointP} />
            <AuthRoute path="/dashboard" component={Dashboard} />
            
          </Structure>


        </Switch>
        <ToastContainer className="f-visby" />
      </BrowserRouter>
    </>
  );
}

export default App;
