import Navbar from "../components/Navbar";

export default function PortfolioPage() {
  return (
    <main>
      <Navbar className="mb-0" />
      <section>
        <div className="bg-brand-blue text-white pt-10 pb-[90px] bg-[url('/assets/images/wave-bg.svg')] bg-blend-soft-light bg-cover">
          <h1 className="text-white text-5xl leading-[56px] tracking-[-1.5px] font-bold mx-auto text-center max-w-[900px]">
            Where creativity meets technology, innovation thrives
          </h1>
        </div>
        <div className="bg-white mb-14 px-[105px] pt-16 pb-14 flex justify-between gap-44 max-w-[70%] mx-auto items-center -mt-16 shadow-[0px_4px_30px_13px_rgba(0,0,0,0.1)]">
          <p className="font-light text-lg leading-7 flex-1 max-w-[480px]">
            We actively seek out and support innovators who dare to dream big
            and have the courage to pursue their visions.
          </p>
          <button className="py-3 px-[30px] font-bold text-xl leading-10 bg-brand-blue text-white rounded-[4px]">
            Get Started
          </button>
        </div>

        <div className="mx-[168px] mb-36">
          <div className="text-center max-w-[40%] mx-auto mb-12">
            <h2 className="font-bold text-4xl tracking-[-1.5px] text-brand-blue mb-1.5 leading-[54px]">
              Portfolio
            </h2>
            <p className="text-black-1">
              We work with corporates, founders and investors to build new
              high-impact ventures that span a variety of digital business
              models across all industry verticals.
            </p>
          </div>

          <div className="grid grid-cols-4 justify-center gap-x-14 gap-y-14">
            <div className="w-[230px] h-[200px] flex items-center justify-center bg-white shadow-[0px_4px_30px_13px_rgba(0,0,0,0.1)]">
              <img src="/assets/images/portfolio-1.svg" />
            </div>
            <div className="w-[230px] h-[200px] flex items-center justify-center bg-white shadow-[0px_4px_30px_13px_rgba(0,0,0,0.1)]">
              <img src="/assets/images/portfolio-2.svg" />
            </div>
            <div className="w-[230px] h-[200px] flex items-center justify-center bg-white shadow-[0px_4px_30px_13px_rgba(0,0,0,0.1)]">
              <img src="/assets/images/portfolio-1.svg" />
            </div>
            <div className="w-[230px] h-[200px] flex items-center justify-center bg-white shadow-[0px_4px_30px_13px_rgba(0,0,0,0.1)]">
              <img src="/assets/images/portfolio-2.svg" />
            </div>

            <div className="w-[230px] h-[200px] flex items-center justify-center bg-white shadow-[0px_4px_30px_13px_rgba(0,0,0,0.1)]">
              <img src="/assets/images/portfolio-1.svg" />
            </div>
            <div className="w-[230px] h-[200px] flex items-center justify-center bg-white shadow-[0px_4px_30px_13px_rgba(0,0,0,0.1)]">
              <img src="/assets/images/portfolio-2.svg" />
            </div>
            <div className="w-[230px] h-[200px] flex items-center justify-center bg-white shadow-[0px_4px_30px_13px_rgba(0,0,0,0.1)]">
              <img src="/assets/images/portfolio-1.svg" />
            </div>
            <div className="w-[230px] h-[200px] flex items-center justify-center bg-white shadow-[0px_4px_30px_13px_rgba(0,0,0,0.1)]">
              <img src="/assets/images/portfolio-2.svg" />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
