import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <>
      <section className="primaryBg py-20 text-white text-center ">

        <div className="md:w-7/12 mx-auto">
          <h1 className="mb-3 font-bold biomeBold text-4xl leading-[50px]">
            Learn more about our fund
          </h1>
          <p className="leading-7 biomelight text-[17px] mb-8 max-w-xl mx-auto">
            Seize the opportunity to be part be of an innovative and promising venture poised for substantial growth and exceptional returns
          </p>

          <div className="md:w-1/2 mx-auto">
            <form className="h-[60px] flex gap-2 justify-center items-center">
              <input
                className="w-full h-full py-2 px-3 rounded-md bg-blue-800 bg-opacity-10 leading-[50px] placeholder:text-white placeholder:opacity-80 placeholder:font-light outline-none"
                placeholder="Enter your email"
              />
              <button
                className="w-28 py-2 px-3 h-full bg-white text-black biomelight rounded-md"
                type="submit"
              >
                Send
              </button>
            </form>
          </div>
        </div>


      </section>



      <footer className="md:container mx-auto">

        <div className="grid grid-cols-12 gap-4 mt-10">

          <div className="md:col-span-3 col-span-12">
            <p className="Montserrat text-gray-600 textCenter">
              Copyright © 2023 <br />
              Ce-IV Company, All rights reserved. <br />
              Designed & Developed by Ce-IV
            </p>
          </div>

          <div className="md:col-span-3 col-span-12">
            <div className="flex flex-col Montserrat textCenter">
              <Link to="#" className="text-gray-600">Privacy Policy</Link>
              <Link to="#" className="text-gray-600">Terms of Use</Link>
              <Link to="#" className="text-gray-600">Disclaimer and Scam Notice</Link>
            </div>
          </div>


          <div className="md:col-span-4 col-span-12 textCenter">
            <h4 className="primaryColor biomeBold text-2xl font-bold">
              Contact Us
            </h4>
            <div className="flex flex-col gap-px Montserrat mx-auto">
              <p className="flex gap-2 text-gray-600 items-start textCenter">
                <img
                  className="mt-2"
                  src="/assets/icons/phone.svg"
                />
                +234 808 595 0244
              </p>

              <p className="flex gap-2 items-start text-gray-600">
                <img
                  className="mt-2 mr-1"
                  src="/assets/icons/email.svg"
                />
                info@ce-iv.com
              </p>

              {/* <p className="flex gap-2.5 items-start text-gray-600">
                <img
                  className="mt-1 mr-3"
                  src="/assets/icons/location.svg"
                />
                380 St Kilda Road, Melbourne VIC 3004, Australia
              </p> */}
            </div>
          </div>
          <div className="md:col-span-2 col-span-12">
            <img src="/assets/icons/logo.svg" className='md:float-right' />
          </div>
        </div>
      </footer>
    </>
  );
}
