import Navbar from "../inc/Navbar";

export default function AboutPage() {
  return (
    <main>

      <div className='border-b'>
        <div className='md:container mx-auto'>
          <Navbar />
        </div>
      </div>

      <div className='md:container mx-auto'>

        <section className="gap-6 mt-6 pb-24">

          <div className="grid grid-cols-12 md:gap-10">

            <div className="md:col-span-6 col-span-12 pt-24 md:order-1 -order-1">
              <h2 className="font-bold text-black md:text-left textCenter biomelight mb-3 md:mt-32 md:mx-0 mx-3">
                We support <span className="biomeBold primaryColor">African entrepreneurs</span> who are building <span className="biomeBold primaryColor">digitally native</span> companies.
              </h2>

              <p className="biomelight md:mx-0 mx-3">Our investment focus is on digitally native businesses with a clearly defined scale potential and proof of early traction, whose products or services are embedded with but not limited to  technologies like Artificial Intelligence and Machine Learning.</p>


            </div>

            <div className="md:col-span-6 col-span-12 order- md:order-2">
              
              {/* <div hidden className="shadow-lg px-3 absolute  lg:bottom-0 bottom-96 mt-32 bg-white" style={{bottom: '200px', marginLeft: '65px', zIndex: '99999'}}>
                <h3 className="biomeBold primaryColor">Over 160</h3>
                <p className="biomelight">Companies Screened</p>
              </div> */}

              <img src="/assets/images/about-illustration.png" className="z-10" />
            </div>


          </div>
        </section>
      </div>

      <section className="py-20" style={{ background: '#FAF8F8' }}>
        <div className="md:w-7/12 lg:mx-auto md:mx-auto">
          {/* <h2 className="section-heading mb-14 text-center biomeBold primaryColor2">Our Ethos</h2> */}
          <div className="grid md:grid-cols-12 grid-rows-12 md:gap-32 gap-4 mt-5">

            <div className="md:col-span-4 col-span-12">
              <div className="pb-5 pt-16 md:px-16 px-5 flex flex-col items-center bg-white shadow-2xl" style={{minHeight: "370px"}}>
                <img className="mb-6" width={76} src="/assets/icons/target.svg" />
                <h5 className="mb-1.5 text-[29px] leading-8 font-bold primaryColor2 biomeBold">
                  Mission
                </h5>
                <p className="text-black-1 text-center text-[15px] leading-7 font-normal biomelight">
                  Leveraging disciplined investment processes to improve the delivery of certainty in venture investing.
                </p>
              </div>
            </div>



            <div className="md:col-span-4 col-span-12">
              <div className="pb-5 pt-16 md:px-16 px-5 flex flex-col items-center bg-white shadow-2xl" style={{minHeight: "370px"}}>
                <img className="mb-3" width={76} src="/assets/icons/mountain.svg" />
                <h5 className="mb-1.5 text-[29px] leading-8 font-bold primaryColor2 biomeBold">
                  Vision
                </h5>
                <p className="text-black-1 text-center text-[15px] leading-7 font-normal textColor biomelight">

                Leader in providing capital to seed-stage digitally native businesses founded by people of African Origin
                 {/* To be the Leading seed stage venture organization backing Africans in the diaspora */}
                </p>
              </div>
            </div>



          </div>
        </div>
      </section>

      <section className="pt-14 mb-48" hidden>

        <div className="md:w-7/12 mx-auto py-20 md:px-0 xs:px-5">
          {/* <h2 className="section-heading mb-[70px] text-center biomeBold">Our Subsidiaries</h2> */}
          <div className="max-w-[810px] flex flex-col items-center mx-auto mb-[90px]">
            <img
              className="w-64 mb-6"
              src="/assets/images/logo.svg"
            />
            <p className="text-lg text-black-1 leading-7 text-center biomelight">
              Ce-IV Managers is a technology advisory firm that specializes in
              providing strategic guidance and expert advice to Ce-IV. Our mission
              is to assist in leveraging technology to drive growth, enhance
              operational efficiency, and gain a competitive edge in the
              ever-evolving digital landscape.
            </p>
          </div>

          <div className="max-w-[810px] flex flex-col items-center mx-auto mb-[84px] mt-24">
            <img
              className="w-64 mb-6"
              src="/assets/images/limited-partners.svg"
            />
            <p className="text-lg text-black-1 leading-7 text-center biomelight">
              Ce-IV Limited Partners has its fund based in Mauritius, is a $10m
              seed venture fund raised to back African diaspora technology
              founders. Our mission is to leverage disciplined investment
              processes to improve the delivery of certainty in venture investing.
            </p>
          </div>
        </div>
      </section>

      
    </main>
  );
}
