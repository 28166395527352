import React, { Component } from 'react';
import NavBar from '../inc/Navbar';
import Structure from '../inc/structure';
import FeatherIcon from 'feather-icons-react';
import FontAwesome from 'react-fontawesome'
import { connect } from 'react-redux';
import { Nav, Spinner, Tab } from 'react-bootstrap';
import userPic from './../dist/images/icons/user.png';
import { Link, NavLink } from 'react-router-dom';
import GetApi from '../api_request/Get';
import { ServerLink } from '../keys/Links';
import notifyError from '../inc/SnackbarError';
import success from '../dist/images/animation/success.gif'


class Dashboard extends Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            isLoading: true,
            rstatus: 0,
        }


        this.getSaveForm()


    }


    componentDidMount() {


        //console.log(this.props.userInfo);

    }



    getSaveForm = (e) => {

        this.setState({
            isLoading: true
        });


        GetApi(ServerLink + 'api/get-form').then(
            res => {
                console.log(res)

                if (res.status === true) {
                    this.setState({
                        rstatus: res.registration_status,
                        rapproval: res.approval,
                        isLoading: false
                    })
                }
                else {
                    //notifyError(res.message, 'error', 'top-center')
                    this.setState({
                        isLoading: false
                    })
                }
            }

        ).catch(e => {
            //console.log(e)
            //notifyError('Oops.. Something went wrong, check your internet connection', 'error', 'top-center')
            this.setState({
                isLoading: false
            })
        })
    }



    getTime() {

        var today = new Date()
        var curHr = today.getHours()

        if (curHr < 12) {
            return ('Good morning')
        } else if (curHr < 18) {
            return ('Good afternoon')
        } else {
            return ("Good evening")
        }
    }


    currentStage = () => {

        if (+this.props.userInfo.approval === 1) {

            return (
                <>
                    <div className='text-center leading-10 mb-5'>
                        <h4 className='biomeBold leading-9'>
                            Congratulation.. You have made it to the next stage.
                        </h4>
                        <p className='biomelight'>Please fill out the UT form and proceed by uploading your UBN file to finalize this section</p>


                    </div>

                    <div className='mb-4'>
                        <a href="/apply-utform" className='grid grid-cols-12 border hover:no-underline hover:shadow-lg hover:bg-gray-100'>
                            <div className='col-span-2'>
                                <div className='bg-blue-600 h-16 w-16 text-center pt-3 border-2'>
                                    {/* <i className='fa fa-file fa-2x text-white'></i> */}

                                    <FeatherIcon
                                        icon={"file-text"}
                                        size={33}
                                        className="inline-block text-white"
                                    />
                                </div>
                            </div>
                            <div className='col-span-8'>
                                <div className='mt-4 mx-3'>
                                    <h6 className='biomelight text-gray-800'>
                                        Kindly click here for UT form registration
                                    </h6>
                                </div>
                            </div>
                            <div className='col-span-2'>
                                {
                                    this.props.userInfo.ut_status ?

                                        <div className='mt-2 float-right pr-1'>
                                            <div className='h-8 w-8 rounded-full mx-auto primaryBg pt-1'>
                                                <FeatherIcon
                                                    icon={'check'}
                                                    className="text-white text-center mx-auto" />
                                            </div>

                                            <small className="text-gray-800 biomelight text-xs">Completed</small>
                                        </div>
                                        :
                                        <>
                                            <div className='mt-2 float-right pr-1'>
                                                <div className='h-8 w-8 rounded-full mx-auto bg-yellow-700 pt-1'>
                                                    <FeatherIcon
                                                        icon={'alert-circle'}
                                                        className="text-white text-center mx-auto" />
                                                </div>

                                                <small className="text-gray-800 biomelight text-xs">Required</small>
                                            </div>
                                        </>
                                }
                            </div>

                        </a>
                    </div>

                    <div>
                        <a href="/upload-ubnform" className='grid grid-cols-12 border hover:no-underline hover:shadow-lg hover:bg-gray-100'>
                            <div className='col-span-2'>
                                <div className='bg-green-700 -600 h-16 w-16 text-center pt-3 border-2 rounded-md'>
                                    <i className='fa fa-file-upload fa-2x text-white'></i>
                                </div>
                            </div>
                            <div className='col-span-8'>
                                <div className='mt-4 mx-3'>
                                    <h6 className='biomelight text-gray-800'>
                                        Click here to upload UBN Form
                                    </h6>
                                </div>
                            </div>
                            <div className='col-span-2'>

                                {
                                    this.props.userInfo.ubn_status ?

                                        <div className='mt-2 float-right pr-1'>
                                            <div className='h-8 w-8 rounded-full mx-auto primaryBg pt-1'>
                                                <FeatherIcon
                                                    icon={'check-circle'}
                                                    className="text-white text-center mx-auto" />
                                            </div>

                                            <small className="text-gray-800 biomelight text-xs">Completed</small>
                                        </div>
                                        :
                                        <>

                                            <div className='mt-2 float-right pr-1'>
                                                <div className='h-8 w-8 rounded-full mx-auto bg-yellow-700 pt-1'>
                                                    <FeatherIcon
                                                        icon={'alert-circle'}
                                                        className="text-white text-center mx-auto" />
                                                </div>

                                                <small className="text-gray-800 biomelight text-xs">Required</small>
                                            </div>

                                        </>
                                }
                            </div>
                        </a>
                    </div>

                </>
            );

        }

        else if (+this.props.userInfo.approval === 2 || this.state.rapproval === 2) {

            return (
                <>
                    <div className='text-center leading-10 mb-5'>
                        <h4 className='biomeBold leading-9'>
                            Thank you for submitting your UT and UBN registration successfully. <br />
                        </h4>

                        <h5 className='biomelight'>
                            Please remain available and expect our communication soon.
                        </h5>
                    </div>
                </>
            );

        }

        else if (this.state.rstatus === 1 || +this.props.userInfo.registration_status === 1 || +this.props.userInfo.approval !== 0) {

            return (
                <>
                    <div className='text-center'>
                        <div className='text-center'>
                            <img src={success} width={120} className='mx-auto text-center' />
                        </div>
                        <h4 className='biomeBold'>
                            Thank you for submitting your registration successfully. We will be in touch with you shortly.
                            <br />
                        </h4>
                        <p className='biomelight'>
                            Please remain available and expect our communication soon.
                        </p>
                    </div>
                </>
            );

        }

        else {

            return (
                <>
                    <div className='text-center'>
                        <h4 className='biomeBold'>You haven't applied yet. Please click the button below to begin your application process.</h4>
                    </div>

                    <div className=' mx-auto mt-4 text-center'>
                        <span className="inline-block mr-1">
                            <a href='/apply' className="bg-white text-blue-700 border-2 biomeBold hover:bg-gray-300 hover:text-black border-blue-700 md:text-base text-sm md:px-5 px-5 py-2">Apply</a>
                        </span>
                    </div>
                </>
            );
        }




    }



    render() {



        return (
            <>
                <div className="cardx">
                    <div className="headerx">
                    </div>
                    <div className="mt-4 float-right md:gap-1 gap-5 flex flex-row">
                        <span className="inline-block mr-1"></span>
                    </div>


                    <div className='md:w-7/12 mx-auto mt-10 py-5 px-5 '>

                        <div className="flex flex-row border-b">
                            <div className="w-16 h-16 flex-shrink-0 bg-gray-300 rounded-full mr-4 p-1">
                                {
                                    !this.props.userInfo.picture ?
                                        <img src={userPic} className="rounded-full" />
                                        :
                                        <img src={this.props.userInfo.picture} className="rounded-full" />
                                }
                            </div>
                            <div className="">
                                <h2 className="font-bold biomeBold">{this.props.userInfo.firstname} </h2>
                                <p className='f-ubuntu'>{this.getTime()}, how are you today</p>
                            </div>
                        </div>
                    </div>


                    <div className='md:w-7/12 mx-auto  py-4 bg-white px-5 shadow-2xl' style={{ minHeight: '150px' }}>

                        {

                            this.state.isLoading ?
                                <>

                                    <div className='text-center'>
                                        <h4 className='biomeBold'>
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="lg"
                                                role="status"
                                                aria-hidden="true"
                                                className="mr-1"
                                            />

                                            Please Wait...
                                        </h4>
                                    </div>

                                </>

                                :
                                <>
                                    {
                                        this.currentStage()
                                    }
                                </>

                        }
                    </div>
                </div>
            </>
        );
    }
}


//export default Dashboard;

const mapStateToProps = state => {

    return {
        loggedIn: state.auth.loggedIn,
        userInfo: state.auth.user

    }
}

/*
const mapDispatchProps = dispatch =>{

    return {
        loggout: () => dispatch({type: 'SET_LOGOUT', })
    }
}

*/

export default connect(mapStateToProps, '')(Dashboard);

