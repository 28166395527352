import Footer from "../components/Footer";

import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react"


import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import 'swiper/modules/navigation/navigation.scss';
// import 'swiper/modules/effect-creative/effect-creative';
import NavBar from "../inc/Navbar";

//import mediax from '../dist/images/mediax.mp4';
import mediax from '../dist/images/magic-carpet-showreel.mp4';


export default function Media() {
  return (
    <main className="font-biome-pro">

      <div className='border-b'>
        <div className='md:container mx-auto'>
          <NavBar />
        </div>
      </div>



      <section className="pt-24 pb-5 px-16 mb-5">

        <div className="md:w-3/5 mx-auto">
          <Swiper
            slidesPerView={1}
            spaceBetween={2}
            navigation={{
              prevEl: ".media-prev",
              nextEl: ".media-next"
            }}
            modules={[Navigation]}
          >

            <SwiperSlide>
              <video className="" autoPlay mutedx loop>
                <source src={mediax} type="video/mp4" />
                This video does not exist
              </video>
            </SwiperSlide>

            <SwiperSlide>
              <video className="" autoPlay mutedx loop>
                <source src={mediax} type="video/mp4" />
                This video does not exist
              </video>
            </SwiperSlide>


            <div className="flex gap-3 justify-center items-center mt-5">

              <button className="media-prev bg-gray-300 rounded-full w-16 h-16 p-2 group cursor-pointer">

                <span className="w-full h-full rounded-full primaryBg stroke-white group-disabled:bg-white flex items-center justify-center group-disabled:group-hover:bg-brand-blue group-disabled:stroke-black-2 group-disabled:group-hover:stroke-white transition-all ease-linear duration-200">

                  <svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.16834 10.8227L21.71 10.7708" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M9.06201 1.80275C9.07174 5.65986 1.0848 10.8229 1.0848 10.8229C1.0848 10.8229 9.09778 15.9455 9.10754 19.8027" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>

                </span>
              </button>

              <button className="media-next bg-gray-300 rounded-full w-16 h-16 p-2 cursor-pointer group">
                <span className="w-full h-full rounded-full primaryBg stroke-white group-disabled:bg-white flex items-center justify-center group-disabled:group-hover:bg-brand-blue group-disabled:stroke-black-2 group-disabled:group-hover:stroke-white transition-all ease-linear duration-200 shadow-[inset_7px_0px_20px_rgba(255,255,255,0.15)]">
                  <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22.0011 10.6099L1.45947 10.6618" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M14.0615 1.62994C14.0713 5.48705 22.0843 10.6097 22.0843 10.6097C22.0843 10.6097 14.0973 15.7727 14.107 19.6299" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </span>
              </button>
            </div>
          </Swiper>
        </div>
      </section>
      <Footer />
    </main>
  );
}
