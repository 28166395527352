import React, { Component } from 'react';
import Error from '../inc/Error';


class Founder extends Component{
    constructor(props, context) {
        super(props, context);

        this.state = {
            errors:{},
            founder_name: "",
            founder_title: "",
            founder_email: ""
        }
    }

    componentDidMount() {
        if (this.props.defaultValues && this.props.defaultValues?.id) {
            const founder_details = {
                founder_name: this.props.defaultValues.fullname,
                founder_title: this.props.defaultValues.title,
                founder_email: this.props.defaultValues.email
            }
            this.setState(founder_details)
            this.props.onChange(this.props.count, founder_details);
        }
    }


    handleChange = (ev) => {
        const { name, value } = ev.target;
        this.setState({ [name]: value })
        const { founder_name, founder_title, founder_email } = this.state;
        let founder_details = { founder_name, founder_title, founder_email }
        founder_details[name] = value
        this.props.onChange(this.props.count, founder_details);
    };

    render(){

        const { count, errors, defaultValues, onChange } = this.props;

        return (
            <div className='mt-4 col-span-6'>
                <div className=''>
                    <label className='primaryColor f-Varela font-bold'>Founder {count}: Full Name: <span className='text-red-400'>*</span></label>
                    <input type='text' className='w-full formBorder border-gray-900 rounded-md py-2 px-3 focus:bg-blue-100 focus:outline-none' name='founder_name' value={this.state.founder_name} onChange={this.handleChange} />
                    <Error error={errors["founder_name"] ? errors["founder_name"] : null} />
                </div>
                <div className='mt-3'>
                    <label className='primaryColor f-Varela font-bold'>Founder {count}:  Title: <span className='text-red-400'>*</span></label>
                    <input max={5} className='w-full formBorder border-gray-500 rounded-md py-2 px-3 focus:bg-blue-100 focus:outline-none' name='founder_title' value={this.state.founder_title} onChange={this.handleChange} />
                    <Error error={errors["founder_title"] ? errors["founder_title"] : null} />
                </div>
                <div className='mt-3'>
                    <label className='primaryColor f-Varela font-bold'>Founder {count}:  Email: <span className='text-red-400'>*</span></label>
                    <input type='text' className='w-full formBorder border-gray-500 rounded-md py-2 px-3 focus:bg-blue-100 focus:outline-none' name='founder_email' value={this.state.founder_email} onChange={this.handleChange} />
                    <Error error={errors["founder_email"] ? errors["founder_email"] : null} />
                </div>
            </div>
        );
    }
}



export default Founder;