import React, { Component } from 'react';
import Error from '../inc/Error';


class Member extends Component{
    constructor(props, context) {
        super(props, context);

        this.state = {
            errors:{},
            member_name: "",
            member_title: "",
            member_email: ""
        }
    }


    handleChange = (ev) => {
        const { name, value } = ev.target;
        this.setState({ [name]: value })
        const { member_name, member_title, member_email } = this.state;
        let member_details = { member_name, member_title, member_email }
        member_details[name] = value
        this.props.onChange(this.props.count, member_details);
    };

    render(){

        const { count, errors, onChange } = this.props;

        return (
            <div className='mt-4 md:col-span-6 col-span-12'>
                <div className=''>
                    <label className='primaryColor f-Varela font-bold'>Key Member {count}: Full Name: <span className='text-red-400'>*</span></label>
                    <input className='w-full formBorder border-gray-900 rounded-md py-2 px-3 focus:bg-blue-100 focus:outline-none' name='member_name' value={this.state.member_name} onChange={this.handleChange} />
                    <Error error={errors["member_name"] ? errors["member_name"] : null} />
                </div>
                <div className='mt-3'>
                    <label className='primaryColor f-Varela font-bold'>Key Member {count}:  Title: <span className='text-red-400'>*</span></label>
                    <input className='w-full formBorder border-gray-500 rounded-md py-2 px-3 focus:bg-blue-100 focus:outline-none' name='member_title'  value={this.state.member_title} onChange={this.handleChange} />
                    <Error error={errors["member_title"] ? errors["member_title"] : null} />
                </div>
                <div className='mt-3'>
                    <label className='primaryColor f-Varela font-bold'>Key Member {count}:  Email: <span className='text-red-400'>*</span></label>
                    <input className='w-full formBorder border-gray-500 rounded-md py-2 px-3 focus:bg-blue-100 focus:outline-none' name='member_email' value={this.state.member_email} onChange={this.handleChange} />
                    <Error error={errors["member_email"] ? errors["member_email"] : null} />
                </div>
            </div>
        );
    }
}



export default Member;