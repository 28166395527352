import { Link } from "feather-icons-react/build/IconComponents";
import Navbar from "../inc/Navbar";

export default function TeamPage() {
  return (
    <main>

      <div className='border-b'>
        <div className='md:container mx-auto'>
          <Navbar />
        </div>
      </div>

      <section className="mt-0 md:mx-20 mb-40 bg-no-repeat bg-contain bg-bottom" style={{ background: "url('/assets/images/world-map.svg') no-repeat" }}>

        <div className="py-10">
          <h2 className="section-heading mb-0 biomeBold primaryColor2 text-center">The Team</h2>
        </div>


        <div className="md:mx-40 grid grid-cols-12 md:gap-10 gap-y-5x justify-evenly">

          <div className="md:col-span-4 col-span-12 leading-5 text-center md:h-fit mb-2">
            <img
              className="inline-block mb-2"
              src="/assets/images/team/3.svg"
              width={150}
            />
            <p className="biomelight mb-0 pb-0 h-6 text-xl py-0">
              Temitope Alliu
            </p>
            <p className="biomelight mt-0 pt-0 text-gray-600 py-0 my-0">
              Investment Associate
            </p>
            <a href="https://www.linkedin.com/in/temitope-alliu-a9798222" target="new">
              <img className="mx-auto mt-2" src="/assets/icons/linkedin.svg" />
            </a>
          </div>



          <div className="md:col-span-4 col-span-12 md:leading-5 text-center col-start-3x h-fitx mb-2">
            <img
              className="inline-block mb-2"
              src="/assets/images/team/2.svg"
              width={150}
            />

            <p className="biomelight mb-0 pb-0 h-6 text-xl py-0">
              Charles Afolabi
            </p>
            <p className="biomelight mt-0 pt-0 text-gray-600 py-0 my-0">
              Chief Investment Officer
            </p>
            <a href="https://www.linkedin.com/in/charlesafolabi01/" target="new">
              <img className="mx-auto mt-2" src="/assets/icons/linkedin.svg" />
            </a>
          </div>




          <div className="md:col-span-4 col-span-12 md:leading-5 text-center col-start-5x h-fitx mb-2">
            <img
              className="inline-block mb-2"
              src="/assets/images/team/1.svg"
              width={150}
            />

            <p className="biomelight mb-0 pb-0 h-6 text-xl py-0">
              Abayomi Awobokun
            </p>
            <p className="biomelight mt-0 pt-0 text-gray-600 py-0 my-0">
              Managing Partner
            </p>
            <a href="https://www.linkedin.com/in/abayomi-awobokun-636b5832" target="new">
              <img className="mx-auto mt-2" src="/assets/icons/linkedin.svg" />
            </a>
          </div>


          <div className="md:col-span-7 col-span-12 md:leading-5 text-center mt-2">
            <img
              className="inline-block mb- pb-0"
              src="/assets/images/team/6.png"
              width={200}
            />

            <p className="biomelight mb-0 pb-0 h-6 text-xl py-0" style={{marginTop:'-13px'}}>
              Chidera Nwankwo
            </p>
            <p className="biomelight mt-0 pt-0 text-gray-600 py-0 my-0 text-sm">
              Analyst
            </p>
            <a href="https://www.linkedin.com/in/chidera-nwankwopr?trk=universal-search-cluster" target="new">
              <img className="mx-auto mt-2" src="/assets/icons/linkedin.svg" />
            </a>

          </div>


          <div className="md:col-span-4 col-span-12 md:leading-5 text-center col-start-2x mt-2">
            <img
              className="inline-block mb-2"
              src="/assets/images/team/4.svg"
              width={150}
            />

            <p className="biomelight mb-0 pb-0 h-6 text-xl py-0">
              Oluwadamilola Igun
            </p>
            <p className="biomelight mt-0 pt-0 text-gray-600 py-0 my-0 text-sm">
              Investment Communication Specialist
            </p>
            <a href="https://www.linkedin.com/in/damilola-igun-b14749154/" target="new">
              <img className="mx-auto mt-2" src="/assets/icons/linkedin.svg" />
            </a>

          </div>
        </div>
      </section>
    </main>
  );
}
