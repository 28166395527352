import React, { Component } from 'react';


import Navbar from "../inc/Navbar";

import video from "./../dist/images/homevideo.mp4";


import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import 'swiper/modules/navigation/navigation.min.css'

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

class Landing extends Component {


  render() {


    return (
      <div className='md:container mx-auto'>
        <section className="max-h-screen overflow-hidden">
          <Navbar />

          <div className='mt-4'>
            <Swiper
              className="my-4"
              speed={1000}
              slidesPerView={1}
              spaceBetween={50}
              navigation={{
                prevEl: ".landing-prev",
                nextEl: ".landing-next",
              }}
              modules={[Navigation, Autoplay]}
              autoplay={{
                delay: 7000,
                disableOnInteraction: false,
              }}
              pagination
            >



              <SwiperSlide>
                <div className="flex justify-between w-full px-12 lg:pl-28 lg:pr-20 lg:text-left gap-16 lg:gap-0">
                  <video className="" autoPlay muted loop>
                    <source src={video} type="video/mp4" />
                    This video does not exist
                  </video>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="mt-0">
                  <img className="w-full" src="../assets/images/1a.png" />
                </div>
              </SwiperSlide>


              <SwiperSlide>
                <div className="mt-0">
                  <img className="w-full" src="../assets/images/1b.png" />
                </div>
              </SwiperSlide>


              <SwiperSlide>
                <div className="mt-0">
                  <img className="w-full" src="../assets/images/1c.png" />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="mt-0">
                  <img className="w-full" src="../assets/images/1d.png" />
                </div>
              </SwiperSlide>

{/* 
              <SwiperSlide>
                <div className="mt-0">
                  <img className="w-full" src="../assets/images/1.png" />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="mt-0">
                  <img className="w-full" src="../assets/images/2.png" />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="mt-0">
                  <img className="w-full" src="../assets/images/3.png" />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="mt-0">
                  <img className="w-full" src="../assets/images/4.png" />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="mt-0">
                  <img className="w-full" src="../assets/images/5.png" />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="mt-0">
                  <img className="w-full" src="../assets/images/6.png" />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="mt-0">
                  <img className="w-full" src="../assets/images/7.png" />
                </div>
              </SwiperSlide>



              <SwiperSlide>
                <div className="mt-0">
                  <img className="w-full" src="../assets/images/8.png" />
                </div>
              </SwiperSlide>

               */}

              
              {/* 
              <SwiperSlide>
                <div className="grid grid-cols-12 gap-10">

                  <div className="md:col-span-5 col-span-12 font-bold">
                    <h1 className="primaryColor biomeBold mt-5 text-4xl">
                      Where creativity meets technology, innovation thrives
                    </h1>
                    <button className="text-white primaryBg rounded-sm px-10 text-xl biomelight py-2 mt-5">
                      Learn More
                    </button>
                  </div>

                  <div className='md:col-span-7 col-span-12'>
                    <img
                      className="w-full"
                      src="../assets/images/illustration-1.png"
                    />
                  </div>

                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="grid grid-cols-12 gap-10">

                  <div className="md:col-span-6 col-span-12 font-bold">
                    <h1 className="primaryColor biomeBold mt-5 text-4xl">
                      Pushing boundaries, redefining limits, and embracing the tech
                      revolution
                    </h1>
                    <button className="text-white primaryBg rounded-sm px-10 text-xl biomelight py-2 mt-5">
                      Learn More
                    </button>
                  </div>

                  <div className='md:col-span-5 col-span-12'>
                    <img
                      className="w-full"
                      src="../assets/images/illustration-2.png"
                    />
                  </div>

                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="grid grid-cols-12 gap-10">

                  <div className="md:col-span-7 col-span-12 font-bold">
                    <h1 className="primaryColor biomeBold mt-5 text-5xl">
                      Ce-IV LP is a fully registered Mauritian entity
                    </h1>
                    <button className="text-white primaryBg rounded-sm px-10 text-xl biomelight py-2 mt-5">
                      Learn More
                    </button>
                  </div>


                  <div className='md:col-span-4 col-span-12'>
                    <img
                      className="w-full"
                      src="../assets/images/illustration-3.png"
                    />
                  </div>

                </div>
              </SwiperSlide> */}

            </Swiper>
          </div>
        </section>
      </div>
    )

  }


}


export default Landing;