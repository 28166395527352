
import Navbar from "../inc/Navbar";

export default function Advisory() {
    return (
        <main>
            <div className='border-b'>
                <div className='md:container mx-auto'>
                    <Navbar />
                </div>
            </div>

            <section className="mt-1 md:mx-20 mb-40 bg-no-repeat bg-contain bg-bottom" style={{ background: "url('/assets/images/world-map.svg') no-repeat" }}>


                <div className="w-5/6 mx-auto">

                    <div className="py-10">
                        <h2 className="section-heading mb-4 biomeBold primaryColor2 text-center">Our Advisory</h2>
                    </div>


                    <div className="md:mx-40 grid grid-cols-12 md:gap-5 gap-y-5x justify-evenly">

                        <div className="md:col-span-6 col-span-12 leading-5 text-center md:h-fit mb-2">
                            <img
                                className="inline-block mb-2"
                                src="/assets/images/team/ad1.svg"
                                width={170}
                            />
                            <p className="biomelight mb-0 pb-0 h-6 text-xl py-0">
                                Dr Olusegun Aganga
                            </p>
                            <p className="biomelight mt-0 pt-0 text-gray-600 py-0 my-0">
                                Chairperson
                            </p>
                            <a href="https://www.linkedin.com/in/olusegun-aganga-74002491/?originalSubdomain=ng" target="new">
                                <img className="mx-auto mt-2" src="/assets/icons/linkedin.svg" />
                            </a>
                        </div>



                        <div className="md:col-span-6 col-span-12 md:leading-5 text-center col-start-3x h-fitx mb-2">
                            <img
                                className="inline-block mb-2"
                                src="/assets/images/team/ad2.svg"
                                width={170}
                            />

                            <p className="biomelight mb-0 pb-0 h-6 text-xl py-0">
                                Abayomi Awobokun
                            </p>
                            <p className="biomelight mt-0 pt-0 text-gray-600 py-0 my-0">
                                Managing Partner
                            </p>
                            <a href="https://www.linkedin.com/in/abayomi-awobokun-636b5832" target="new">
                                <img className="mx-auto mt-2" src="/assets/icons/linkedin.svg" />
                            </a>
                        </div>






                        <div className="md:col-span-6 col-span-12 md:leading-5 text-center mt-">
                            <img
                                className="inline-block mb-2"
                                src="/assets/images/team/ad3.svg"
                                width={157}
                            />

                            <p className="biomelight mb-0 pb-0 h-6 text-xl py-0">
                                Abisola Fatokun
                            </p>
                            <p className="biomelight mt-0 pt-0 text-gray-600 py-0 my-0 text-sm">
                                Advisor
                            </p>
                            <a href="https://www.linkedin.com/in/abisola/" target="new">
                                <img className="mx-auto mt-2" src="/assets/icons/linkedin.svg" />
                            </a>

                        </div>


                        <div className="md:col-span-6 col-span-12 md:leading-5 text-center col-start-2x mt-">
                            <img
                                className="inline-block mb-2"
                                src="/assets/images/team/ad4.png"
                                width={170}
                            />

                            <p className="biomelight mb-0 pb-0 h-6 text-xl py-0">
                                Adesola Ologbosere

                            </p>
                            <p className="biomelight mt-0 pt-0 text-gray-600 py-0 my-0 text-sm">
                                Venture Partner
                            </p>
                            <a href="#">
                                <img className="mx-auto mt-2" src="/assets/icons/linkedin.svg" />
                            </a>

                        </div>
                    </div>
                </div>


            </section>
        </main>
    );
}
