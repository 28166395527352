import React, { Component } from 'react'
import FeatherIcon from 'feather-icons-react';

import { ListGroup, Container, Row, Col } from 'react-bootstrap';

import { Link, NavLink } from 'react-router-dom';
import { render } from 'react-dom';

import { connect } from 'react-redux';

import cookie from 'js-cookie';
import userPic from './../dist/images/icons/user.png';
import logo from './../dist/images/logo.svg';


class Structure extends Component {

    constructor(props) {
        super(props);

        this.state = {
            open: true,
            hidex: '',
            user: this.props.userInfo,
        };


        //console.log(this.props.userInfo)

    }

    state = {
        navStatus: 0,
    }


    openNav = () => {

        if (this.state.open == true) {
            this.setState({
                open: false,
                hidex: 'hidden'
            })

            document.getElementById('myNav').style.width = '250px';
            document.getElementById('mainBody').style.left = '250px';
            document.getElementById('mainBody').style.width = '500px';
            //tags[0].setAttribute('hidden', "hidden")
        }

        else {

            this.setState({
                open: true,
                hidex: 'visible'
            })
            document.getElementById('myNav').style.width = '0px'
            document.getElementById('mainBody').style.left = '0'
            document.getElementById('mainBody').style.width = '100%';
        }

    }

    handleLogout = (e) => {

        e.preventDefault();

        cookie.remove('token');
        cookie.remove('firstname');
        cookie.remove('lastname');
        this.props.loggout();
        window.location.reload()

    }

    render() {

        const { open, hidex } = this.state;

        return (

            <div>

                <div className="">

                    <div className="myNav shadow-2xl" id="myNav" style={{}}>



                        <div className="sideNav">

                            <div className="text-left pb-3 bg-whitex pt-3">

                                <Link to="/" className="block mx-auto text-center">
                                    <span className="inline-block text-center">
                                        <img src={logo} className="ml-5 mb-5 mx-auto" width={90} />

                                    </span>
                                    {/* <span className="inline-block mt-1 ml-3 text-black workSanMedium f-s-18">CE-IV</span> */}
                                </Link>
                            </div>

                            <ul className="mt-5">
                                <li className="navx"><NavLink activeClassName="active" to="/dashboard"> <i className="fa fa-home"></i> <span className="navText text-sm biomelight"> Home </span> </NavLink></li>

                                <li className="navx"><NavLink to="/pointp"><i className="fa fa-file"></i> <span className="navText biomelight text-sm"> PointP </span></NavLink></li>
                                
                                <li className="navx"><NavLink to="/account"><i className="fa fa-user"></i> <span className="navText biomelight text-sm"> Account </span></NavLink></li>

                                <li className="navx"><NavLink to="/update-password"> <i className="fa fa-credit-card"></i> <span className="navText text-sm biomelight"> Change Password </span></NavLink></li>

                                <li className=""><Link onClick={this.handleLogout}><FeatherIcon icon="log-out" className="inline-block f-s-20" hidden /> <i className="fa fa-sign-out-alt"></i><span className="navText text-sm biomelight">Logout</span> </Link></li>
                            </ul>
                        </div>
                    </div>
                </div>


                <div className="mainBody pb-4" id="mainBody">
                    <div className="structure-header lg:hiddenx">

                        <Container>
                            <Row>

                                <Col xs={2} sm={3}>
                                    <div className="pl-3 pt-4 mb-4 mobileView">
                                        <Link onClick={this.openNav}>
                                            <FeatherIcon icon="menu" className="text-gray-300" style={{ fontSize: '50px' }} />
                                        </Link>
                                    </div>
                                </Col>

                                <Col xs={7} sm={10}>
                                    <div className="float-left inline-block pt-4" hidden>
                                        <Link onClick={this.openNav}>
                                            <FeatherIcon icon="menu" className="text-gray-300" style={{ fontSize: '50px' }} />
                                        </Link>
                                    </div>

                                    <div className="pt-4 text-center">
                                        <h6 style={{ fontWeight: 'bolder' }} className={this.props.colorx + " text-center f-s-18 workSanMedium f-w-700 p-t-8 workSan"}>{this.props.title} </h6>
                                    </div>
                                </Col>

                                <Col xs={3} sm={2}>
                                    <div className="pt-4 flex flex-row">


                                        <div className="mr-3">
                                            <Link to="notifications" className="text-white">

                                                <FeatherIcon icon="bell" className="fa fa-bell block z-0 relative" style={{ marginTop: '10px', marginLeft: '-20x' }} />
                                                <span className="bg-red-700 w-6 h-6 block text-center rounded-full z-50 absolute f-ubuntu" style={{ marginTop: '-35px', marginRight: '5px' }}>0</span>
                                            </Link>
                                        </div>



                                        <div className="bg-gray-100 h-8 w-8 rounded-full mr-2">
                                            {
                                                !this.props.userInfo.picture ?

                                                    <img src={userPic} />
                                                    :
                                                    <img src={this.props.userInfo.picture ?? userPic} />
                                            }
                                        </div>
                                        <span className="workSanMedium mt-1 hidden sm:block">
                                            {this.props.userInfo.firstname ?? ''}
                                        </span>
                                        <FeatherIcon icon="arrow-right" className="mt-1" />
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>

                    <div className="lg:pt-4" style={{ position: 'relative', minHeight: '100vh' }}>


                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => {

    return {
        loggedIn: state.auth.loggedIn,
        userInfo: state.auth.user

    }
}


const mapDispatchProps = dispatch => {

    return {
        loggout: () => dispatch({ type: 'SET_LOGOUT', })
    }
}

export default connect(mapStateToProps, mapDispatchProps)(Structure);




//export default Structure;