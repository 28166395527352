import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import success from '../dist/images/animation/success.gif'

class Complete extends Component {

    render() {

        return (

            <>
                <div className='cardx mt-32'>

                    <div className='md:w-7/12 mx-auto  py-10 bg-white px-5 shadow-2xl' style={{ minHeight: '150px' }}>
                        <div className='text-center'>
                            <div className='text-center'>
                                <img src={success} width={120} className='mx-auto text-center' />
                            </div>
                            <h4 className='biomeBold'>
                                Your form has been successfully submitted. Thank you.
                                <br />
                            </h4>
                            <p className='biomelight'>
                                Kindly click the button below to return to the dashboard.
                            </p>
                        </div>

                        <div className=' mx-auto mt-4 text-center pb-5'>
                            <span className="inline-block mr-1 mt-10">
                                <a href='/dashboard' className="bg-white text-blue-700 border-2 biomeBold hover:bg-gray-300 hover:text-black hover:no-underline border-blue-700 md:text-base text-sm md:px-5 px-5 py-2">Go to Dashboard</a>
                            </span>
                        </div>
                    </div>
                </div>
            </>
        );
    }



}



const mapDispatchToProps = dispatch => {

    return {

        setLogin: user => dispatch({ type: "SET_LOGIN", payload: user })
    }
}

export default connect(null, mapDispatchToProps)(Complete);