import React, { Component } from 'react';
import { connect } from 'react-redux';

import Error from '../inc/Error';
import postApi from '../api_request/Post';
import { ServerLink } from '../keys/Links';
import notifyError from '../inc/SnackbarError';
import { Spinner } from 'react-bootstrap';
import NavBar from '../inc/Navbar';



class Utform extends Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            fields: {},
            errors: {},
            isLoading: false,
        }
    }

    handleInput = (e) => {
        e.preventDefault();

        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value })

    }


    handleForm = (e) => {
        e.preventDefault();

        let errors = {};
        let formIsValid = true;

        if (!this.state.solution_architecture) {
            formIsValid = false;
            errors["solution_architecture"] = "This field cannot be empty";
        }

        if (!this.state.collaboration) {
            formIsValid = false;
            errors["collaboration"] = "This field cannot be empty";
        }

        if (!this.state.development_environment) {
            formIsValid = false;
            errors["development_environment"] = "This field cannot be empty";
        }

        if (!this.state.cloud) {
            formIsValid = false;
            errors["cloud"] = "This field cannot be empty";
        }

        if (!this.state.src) {
            formIsValid = false;
            errors["src"] = "This field cannot be empty";
        }

        if (!this.state.tech_disruptor) {
            formIsValid = false;
            errors["tech_disruptor"] = "This field cannot be empty";
        }

        if (!this.state.enterprise_tools) {
            formIsValid = false;
            errors["enterprise_tools"] = "This field cannot be empty";
        }

        if (!this.state.accessibility) {
            formIsValid = false;
            errors["accessibility"] = "This field cannot be empty";
        }

        if (!this.state.data_tools) {
            formIsValid = false;
            errors["data_tools"] = "This field cannot be empty";
        }



        if (!this.state.quality_code) {
            formIsValid = false;
            errors["quality_code"] = "This field cannot be empty";
        }


        if (!this.state.scalability) {
            formIsValid = false;
            errors["scalability"] = "This field cannot be empty";
        }


        if (!this.state.maintenance) {
            formIsValid = false;
            errors["maintenance"] = "This field cannot be empty";
        }

        if (!this.state.ip) {
            formIsValid = false;
            errors["ip"] = "This field cannot be empty";
        }

        if (!this.state.security_standard) {
            formIsValid = false;
            errors["security_standard"] = "This field cannot be empty";
        }


        this.setState({ errors: errors });

        if (formIsValid) {


            this.setState({
                isLoading: true,
            })
            console.log(this.state)


            const data = {
                company: this.props.userInfo.company,
                solution_architecture: this.state.solution_architecture,
                collaboration: this.state.collaboration,
                development_environment: this.state.development_environment,
                cloud: this.state.cloud,
                src: this.state.src,
                tech_disruptor: this.state.tech_disruptor,
                enterprise_tools: this.state.enterprise_tools,
                accessibility: this.state.accessibility,
                data_tools: this.state.data_tools,
                quality_code: this.state.quality_code,
                scalability: this.state.scalability,
                maintenance: this.state.maintenance,
                ip: this.state.ip,
                security_standard: this.state.security_standard,
            }

            console.log(data);

            postApi(ServerLink + 'api/save-form-ut', data).then(
                res => {
                    if (res.status === true) {
                        this.setState({ isLoading: false })
                        this.props.history.push('/complete')
                    }
                    else {
                        notifyError(res.message, 'error', 'top-center');
                    }
                    console.log(e)
                }
            ).catch(e => {
                notifyError(e, 'error', 'top-center');
                this.setState({
                    isLoading: false,
                })
                console.log(e)
            })


        }
    }

    render() {



        return (
            <>
                <div className='border-bottom'>
                    <NavBar />
                </div>
                <section className='mt-5'>

                    <div className='container mx-auto mb-20'>
                        <div className='my-5'>
                            <h2 className='biomeBold font-extrabold text-center primaryColor mb-10'>UT Form</h2>
                        </div>


                        {
                            +this.props.userInfo.approval === 2 ?

                                <>

                                    <div className='mt-4'>
                                        <h4 className='text-center biomeBold'>You have successfully submitted you UT Registration</h4>
                                    </div>

                                </>
                                :
                                <form onSubmit={this.handleForm} method="post">
                                    <div className=''>


                                        <div className='mt-4'>
                                            <label className='primaryColor biomelight font-bold'> Company Name <span className='text-red-400'>*</span></label>
                                            <input className='w-full formBorder bg-gray-100 border-gray-500 rounded-md py-2 px-3 focus:bg-blue-100 focus:outline-none' defaultValue={this.state.company ?? this.props.userInfo.company} readOnly name='company' onChange={this.handleInput} />
                                            <Error error={this.state.errors["company"] ? this.state.errors["company"] : null} />
                                        </div>

                                        <div className='mt-4'>
                                            <label className='primaryColor biomelight font-bold'>Solution Architecture <span className='text-red-400'>*</span></label>
                                            <textarea className='w-full px-3 formBorder border-gray-500 rounded-md focus:bg-blue-100 focus:outline-none' name='solution_architecture' onChange={this.handleInput} rows={5}></textarea>
                                            <Error error={this.state.errors["solution_architecture"] ? this.state.errors["solution_architecture"] : null} />
                                            <small className='biomelight text-gray-700'>Does the company have the physical hardware and software needed to support software/analytics development and deployment? Does it meet the different architectural viewpoints (business, information, and technical)?</small>
                                        </div>

                                        <div className='mt-4'>
                                            <label className='primaryColor biomelight font-bold'>Collaboration Platform<span className='text-red-400'>*</span></label>
                                            <textarea className='w-full px-3 formBorder border-gray-500 rounded-md focus:bg-blue-100 focus:outline-none' name='collaboration' onChange={this.handleInput} rows={5}></textarea>
                                            <Error error={this.state.errors["collaboration"] ? this.state.errors["collaboration"] : null} />
                                            <small className='biomelight text-gray-700'>To what extent are deliverables developed from collaboration platforms to also leverage existing knowledge?</small>
                                        </div>

                                        <div className='mt-4'>
                                            <label className='primaryColor biomelight font-bold'>Development & Testing Environment <span className='text-red-400'>*</span></label>
                                            <textarea className='w-full px-3 formBorder border-gray-500 rounded-md focus:bg-blue-100 focus:outline-none' name='development_environment' onChange={this.handleInput} rows={5}></textarea>
                                            <Error error={this.state.errors["development_environment"] ? this.state.errors["development_environment"] : null} />
                                            <small className='biomelight text-gray-700'>To what extent is development, testing and production done in separate environments? How well do these environments meet your needs and global standards?</small>
                                        </div>

                                        <div className='mt-4'>
                                            <label className='primaryColor biomelight font-bold'>Cloud vs. On-Premise<span className='text-red-400'>*</span></label>
                                            <textarea className='w-full px-3 formBorder border-gray-500 rounded-md focus:bg-blue-100 focus:outline-none' name='cloud' onChange={this.handleInput} rows={5}></textarea>
                                            <Error error={this.state.errors["cloud"] ? this.state.errors["cloud"] : null} />
                                            <small className='biomelight text-gray-700'>To what extent do you use cloud-based applications or processing power for performing business analytics and software development?</small>
                                        </div>


                                        <div className='mt-4'>
                                            <label className='primaryColor biomelight font-bold'>Security, Reliability & Continuity<span className='text-red-400'>*</span></label>
                                            <textarea className='w-full px-3 formBorder border-gray-500 rounded-md focus:bg-blue-100 focus:outline-none' name='src' onChange={this.handleInput} rows={5}></textarea>
                                            <Error error={this.state.errors["src"] ? this.state.errors["src"] : null} />
                                            <small className='biomelight text-gray-700'>To what extent are codes and IT systems secure and stable and can they support additional capacity without affecting performance?</small>
                                        </div>

                                        <div className='mt-4'>
                                            <label className='primaryColor biomelight font-bold'>Tech Disruptors and Vendor Strategy<span className='text-red-400'>*</span></label>
                                            <textarea className='w-full px-3 formBorder border-gray-500 rounded-md focus:bg-blue-100 focus:outline-none' name='tech_disruptor' onChange={this.handleInput} rows={5}></textarea>
                                            <Error error={this.state.errors["tech_disruptor"] ? this.state.errors["tech_disruptor"] : null} />
                                            <small className='biomelight text-gray-700'>Do you incorporate new disruptive technologies into the IT strategy and does sophisticated vendor management exist?</small>
                                        </div>

                                        <div className='mt-4'>
                                            <label className='primaryColor biomelight font-bold'>Enterprise Analytics Tools<span className='text-red-400'>*</span></label>
                                            <textarea className='w-full px-3 formBorder border-gray-500 rounded-md focus:bg-blue-100 focus:outline-none' name='enterprise_tools' onChange={this.handleInput} rows={5}></textarea>
                                            <Error error={this.state.errors["enterprise_tools"] ? this.state.errors["enterprise_tools"] : null} />
                                            <small className='biomelight text-gray-700'>To what extent are analytics tools integrated across the organization in operational processes?</small>
                                        </div>

                                        <div className='mt-4'>
                                            <label className='primaryColor biomelight font-bold'>Accessibility<span className='text-red-400'>*</span></label>
                                            <textarea className='w-full px-3 formBorder border-gray-500 rounded-md focus:bg-blue-100 focus:outline-none' name='accessibility' onChange={this.handleInput} rows={5}></textarea>
                                            <Error error={this.state.errors["accessibility"] ? this.state.errors["accessibility"] : null} />
                                            <small className='biomelight text-gray-700'>To what extent are analytics tools accessible across the organization?</small>
                                        </div>

                                        <div className='mt-4'>
                                            <label className='primaryColor biomelight font-bold'>Data Science Tools<span className='text-red-400'>*</span></label>
                                            <textarea className='w-full px-3 formBorder border-gray-500 rounded-md focus:bg-blue-100 focus:outline-none' name='data_tools' onChange={this.handleInput} rows={5}></textarea>
                                            <Error error={this.state.errors["data_tools"] ? this.state.errors["data_tools"] : null} />
                                            <small className='biomelight text-gray-700'>Do you have analytics tools and capabilities in the enterprise to support insight delivery?</small>
                                        </div>


                                        <div className='mt-4'>
                                            <label className='primaryColor biomelight font-bold'>Quality of Code and user interface<span className='text-red-400'>*</span></label>
                                            <textarea className='w-full px-3 formBorder border-gray-500 rounded-md focus:bg-blue-100 focus:outline-none' name='quality_code' onChange={this.handleInput} rows={5}></textarea>
                                            <Error error={this.state.errors["quality_code"] ? this.state.errors["quality_code"] : null} />
                                            <small className='biomelight text-gray-700'>Does the code meet best practices and state-of-the-art technology standards? Have the developers choose the right technology for the application, and has that technology been applied correctly?</small>
                                        </div>


                                        <div className='mt-4'>
                                            <label className='primaryColor biomelight font-bold'>Scalability<span className='text-red-400'>*</span></label>
                                            <textarea className='w-full px-3 formBorder border-gray-500 rounded-md focus:bg-blue-100 focus:outline-none' name='scalability' onChange={this.handleInput} rows={5}></textarea>
                                            <Error error={this.state.errors["scalability"] ? this.state.errors["scalability"] : null} />
                                            <small className='biomelight text-gray-700'> What is the ability to handle a growing user base? </small>
                                        </div>

                                        <div className='mt-4'>
                                            <label className='primaryColor biomelight font-bold'>Maintenance<span className='text-red-400'>*</span></label>
                                            <textarea className='w-full px-3 formBorder border-gray-500 rounded-md focus:bg-blue-100 focus:outline-none' name='maintenance' onChange={this.handleInput} rows={5}></textarea>
                                            <Error error={this.state.errors["maintenance"] ? this.state.errors["maintenance"] : null} />
                                            <small className='biomelight text-gray-700'> What effort is in place for maintaining the code base and for ensuring seamless operations.? Are several developers able to maintain the software solution or the know-how lies all with one/few person(s)? </small>
                                        </div>

                                        <div className='mt-4'>
                                            <label className='primaryColor biomelight font-bold'>IP<span className='text-red-400'>*</span></label>
                                            <textarea className='w-full px-3 formBorder border-gray-500 rounded-md focus:bg-blue-100 focus:outline-none' name='ip' onChange={this.handleInput} rows={5}></textarea>
                                            <Error error={this.state.errors["ip"] ? this.state.errors["ip"] : null} />
                                            <small className='biomelight text-gray-700'> Is the code/intellectual property completely owned by the entity? </small>
                                        </div>

                                        <div className='mt-4'>
                                            <label className='primaryColor biomelight font-bold'>Security Standards<span className='text-red-400'>*</span></label>
                                            <textarea className='w-full px-3 formBorder border-gray-500 rounded-md focus:bg-blue-100 focus:outline-none' name='security_standard' onChange={this.handleInput} rows={5}></textarea>
                                            <Error error={this.state.errors["security_standard"] ? this.state.errors["security_standard"] : null} />
                                            <small className='biomelight text-gray-700'> What third-party solutions and libraries are used, and do they potentially have security vulnerabilities? </small>
                                        </div>


                                        <div className='col-span-4 my-5 pb-20'>
                                            <div className='md:float-left'>
                                                {
                                                    this.state.isLoading ?
                                                        <button className='bg-black biome font-bold text-white px-3 py-2 rounded-full text-sm'>  LOADING.... </button>
                                                        :
                                                        <button type='submit' className='primaryBg biomelight font-bold text-white px-3 py-2 rounded-full text-sm' style={{ fontSize: '18px' }}>SUBMIT</button>
                                                }

                                            </div>
                                        </div>


                                    </div>
                                </form>
                        }
                    </div>
                </section>
            </>
        );
    }


}



const mapStateToProps = state => {

    return {
        loggedIn: state.auth.loggedIn,
        userInfo: state.auth.user

    }
}


const mapDispatchProps = dispatch => {

    return {
        loggout: () => dispatch({ type: 'SET_LOGOUT', })
    }
}

export default connect(mapStateToProps, mapDispatchProps)(Utform);
