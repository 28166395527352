import React, { Component } from 'react';

//import NavBar from "../inc/Navbar";
//import { Link } from "react-router-dom";
import Error from '../inc/Error';
//import { Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import cookie from 'js-cookie';
//import PhoneInput from 'react-phone-input-2'
//import 'react-phone-input-2/lib/style.css'
import postApi from '../api_request/Post';
import { ServerLink } from '../keys/Links';
import notifyError from '../inc/SnackbarError';
import NavBar from '../inc/Navbar';



class Register extends Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            first: '',
            lastname: '',
            email: '',
            phone_code: '234',
            phone: '',
            password: '',
            referral: '',
            fields: {},
            errors: {},
            isLoading: false
        }

        var locationData = this.props.location.state;

        if (locationData) {
            this.state.fields = locationData
        }


    }

    handleInput = (e) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value });
    }

    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }

    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        //firstname
        if (!fields["company"]) {
            formIsValid = false;
            errors["company"] = "Company name cannot be empty";
        }

        if (!fields["firstname"]) {
            formIsValid = false;
            errors["firstname"] = "Firstname cannot be empty";
        }

        if (typeof fields["firstname"] !== "undefined") {
            if (!fields["firstname"].match(/^[a-zA-Z -]+$/)) {
                formIsValid = false;
                errors["firstname"] = "Only letters";
            }
        }

        //lastname
        if (!fields["lastname"]) {
            formIsValid = false;
            errors["lastname"] = "Lastname cannot be empty";
        }

        if (typeof fields["lastname"] !== "undefined") {
            if (!fields["lastname"].match(/^[a-zA-Z -]+$/)) {
                formIsValid = false;
                errors["lastname"] = "Only letters";
            }
        }


        //Email
        if (!fields["email"]) {
            formIsValid = false;
            errors["email"] = "Email cannot be empty";
        }

        if (typeof fields["email"] !== "undefined") {
            let lastAtPos = fields["email"].lastIndexOf('@');
            let lastDotPos = fields["email"].lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') == -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
                formIsValid = false;
                errors["email"] = "Email is not valid";
            }
        }


        //phone number
        if (!fields["phone"]) {
            formIsValid = false;
            errors["phone"] = "Phone Number cannot be empty";
        }

        if (typeof fields["phone"] !== "undefined") {

            var phoneNo = fields["phone"];

            if (phoneNo === parseInt(fields["phone"], 10)) {
                formIsValid = false;
                errors["phone"] = "Invalid Phone Number " + fields["phone"];
            }
        }


        //password

        if (!fields["password"]) {
            formIsValid = false;
            errors["password"] = "Password cannot be empty";
        }

        if (typeof fields["password"] !== "undefined") {
            if (fields["password"].length <= '5') {
                formIsValid = false;
                errors["password"] = "Password length is too short, Min of 6 character";
            }
        }

        this.setState({ errors: errors });
        return formIsValid;
    }


    onSubmit = (e) => {

        e.preventDefault();


        if (this.handleValidation()) {

            this.setState({

                isLoading: true,
            })

            //proccess the form
            let fields = this.state.fields;

            const data = {
                company: fields['company'],
                firstname: fields['firstname'],
                lastname: fields['lastname'],
                email: fields['email'],
                phone: fields['phone'],
                password: fields['password'],
                
            }

            postApi(ServerLink + 'api/register', data).then(
                res => {

                    if (res.status === true) {

                        console.log(res)
                        cookie.set('token', res.token);
                        cookie.set('firstname', res.data.firstname);
                        cookie.set('lastname', res.data.lastname);

                        this.setState({
                            isLoading: false,
                            errors: {
                                'error': ''
                            }
                        })

                        notifyError('Registration Successful', 'success', 'top-center')


                        setTimeout(() => {
                            //window.location.reload();
                            this.props.setLogin(res.data);
                        }, 2050);

                    }
                    else {

                        notifyError('Error: Check your input data', 'error', 'top-center');

                        this.setState({

                            isLoading: false,
                        })

                        console.log(res)
                    }
                }
            ).catch(e => {
                notifyError(e, 'error', 'top-center');

                this.setState({
                    isLoading: false,
                })

                console.log(e)
            })
        }

        else {
            console.log('Error')
        }
    }


    render() {
        const showPassword = () => {

            var getPassword = document.getElementById('password');
            var cPassword = document.getElementById('cpassword');

            if (getPassword.type === "password") {
                getPassword.type = "";
                cPassword.type = ""
            } else {
                getPassword.type = "password";
                cPassword.type = "password";

            }
        }


        return (

            <>
              <div className='border-b'>
                    <div className='md:container mx-auto'>
                        <NavBar />
                    </div>
                </div>
                <div className='mt-16'>
                    <div className='md:w-4/12 mx-auto'>
                        <div>
                            <h1 className="primaryColor text-center biomeBold font-bold">Applicant Registration</h1>
                        </div>

                        <div className="md:px-1 px-3 mt-5 mx-auto">

                            <div className="mt-5">
                                <form className="mx-3 my-5" onSubmit={this.onSubmit}>
                                    <div className="grid grid-cols-12 gap-5">

                                        <div className="col-span-12 mt-1 workSan">
                                            <label>Company Name*</label>
                                            <Error error={this.state.errors['company'] ? this.state.errors['company'] : null} />
                                            <input onChange={this.handleChange.bind(this, "company")} name="company" type="text" id="company" className="border w-full border-gray-600  py-2 px-3 bg-gray-100 focus:bg-blue-100 focus:outline-none focus:border-blue-700" />
                                        </div>


                                        <div className="col-span-12 mt-1 workSan">
                                            <label>Password*</label>
                                            <Error error={this.state.errors['password'] ? this.state.errors['password'] : null} />
                                            <input onChange={this.handleChange.bind(this, "password")} name="password" id="password" type="password" className="border w-full border-gray-600  py-2 px-3 bg-gray-100 focus:bg-blue-100 focus:outline-none focus:border-blue-700" />
                                            
                                        </div>

                                        <div className="col-span-12 mt-1 workSan">
                                            <label>Confirm Password*</label>
                                            <Error error={this.state.errors['cpassword'] ? this.state.errors['cpassword'] : null} />
                                            <input onChange={this.handleChange.bind(this, "cpassword")} name="cpassword" id="cpassword" type="password" className="border w-full border-gray-600  py-2 px-3 bg-gray-100 focus:bg-blue-100 focus:outline-none focus:border-blue-700" placeholder='Confirm Password' />
                                            
                                        </div>


                                        
                                        <div className="col-span-12 mt-1 workSan">
                                            <div className='w-full'>
                                                <button type="button" toggle="#password-field" onClick={showPassword} className="fa fa-fw fa-eye field-icon toggle-password mt-3 focus:outline-none text-gray-500 w-40 float-right" style={{ marginLeft: '0px' }}> <span className='primaryColor f-Varela text-sm'>Show Password</span></button> 
                                            </div>
                                        </div>
                                        <div className="col-span-12 mt-1 workSan">
                                            <label>First name*</label>
                                            <Error error={this.state.errors['firstname'] ? this.state.errors['firstname'] : null} />
                                            <input onChange={this.handleChange.bind(this, "firstname")} name="firstname" type="text" id="firstname" className="border w-full border-gray-600  py-2 px-3 bg-gray-100 focus:bg-blue-100 focus:outline-none focus:border-blue-700" />
                                        </div>

                                        <div className="col-span-12 mt-1 workSan">
                                            <label>Last name*</label>
                                            <Error error={this.state.errors['lastname'] ? this.state.errors['lastname'] : null} />
                                            <input onChange={this.handleChange.bind(this, "lastname")} name="lastname" type="text" id="lastname" className="border w-full border-gray-600  py-2 px-3 bg-gray-100 focus:bg-blue-100 focus:outline-none focus:border-blue-700" />
                                        </div>

                                        <div className="col-span-12 mt-1 workSan">
                                            <label>Email*</label>
                                            <Error error={this.state.errors['email'] ? this.state.errors['email'] : null} />
                                            <input onChange={this.handleChange.bind(this, "email")} name="email" type="email" id="email" className="border w-full border-gray-600  py-2 px-3 bg-gray-100 focus:bg-blue-100 focus:outline-none focus:border-blue-700"/>
                                        </div>

                                        <div className="col-span-12 mt-1 workSan">
                                            <label>Phone*</label>
                                            <Error error={this.state.errors['phone'] ? this.state.errors['phone'] : null} />
                                            <input onChange={this.handleChange.bind(this, "phone")} name="phone" type="tel" id="phone" className="border w-full border-gray-600  py-2 px-3 bg-gray-100 focus:bg-blue-100 focus:outline-none focus:border-blue-700" />
                                        </div>


                                        <div className="col-span-12 mt-1 workSan">
                                            {
                                                this.state.isLoading
                                                    ?
                                                    <button className="bg-black hover:bg-gray-700 text-white font-bold workSan w-full py-3">
                                                        {/* <Spinner
                                                            as="span"
                                                            animation="border"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                            className="mr-1"
                                                        /> */}
                                                        Loading...
                                                    </button>

                                                    :
                                                    <button type="submit" className="border w-full border-gray-600  py-2 px-3 primaryBg text-white rounded-md">Register</button>
                                            }
                                        </div>

                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        );
    }

}



//export default Register;



const mapDispatchToProps = dispatch => {

    return {

        setLogin: user => dispatch({ type: "SET_LOGIN", payload: user })
    }
}

export default connect(null, mapDispatchToProps)(Register);