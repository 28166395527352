import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import { useState } from "react";

export default function Navbar({ className }) {
  const [showSide, setShowSide] = useState(false)

  return (
    <nav
      className={
        "flex justify-between items-center flex-row py-8 px-5 md:px-10 lg:px-16 xl:px-20 mb-7" + className || ""
      }
    >
      <Link to="/">
        <img alt="CE-IV Logo" src="/assets/images/logo.svg" />
      </Link>

      <div className="gap-[34px] text-brand-blue font-light text-lg leading-[18px] hidden min-[840px]:flex font-biome-pro">
        <Link className="nav-link" to="/">Home</Link>
        <Link className="nav-link" to="/about">About Us</Link>
        <Link className="nav-link" to="/portfolio">Portfolio</Link>
        <Link className="nav-link" to="/team">Team</Link>
        <Link className="nav-link" to="/media">Media</Link>
      </div>

      <div className="flex gap-10 items-center">
        <a onClick={() => setShowSide(!showSide)}>
          <svg className="block min-[840px]:hidden" width="24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M24 21h-24v-2h24v2zm0-4.024h-24v-2h24v2zm0-3.976h-24v-2h24v2zm0-4h-24v-2h24v2zm0-6v2h-24v-2h24z"/></svg>
        </a>
        <button className="border border-1 border-brand-blue py-2.5 px-12 rounded-md font-bold text-brand-blue tracking-[-0.16px] font-biome-bold hollow-hover">
          Apply
        </button>
      </div>

      <Sidebar show={showSide} onClose={() => setShowSide(false)} />
    </nav>
  );
}
