import React, { Component } from 'react';
import { connect } from 'react-redux';



class Forgot extends Component {


    render() {


        return (
            <>
                <div className='mt-32'>
                    <div className='md:w-4/12 mx-auto'>
                        <div>
                            <p className='text-gray-700 text-center f-Varela text-lg'>To reset your password, please enter your email address or username below.</p>
                        </div>
                        <div className='mt-4'>
                            <input className='px-3 py-2 rounded-sm border w-full' placeholder='Enter your email address' />
                        </div>

                        <div className='mt-5 text-center w-full'>
                            <button className='primaryBg px-5 py-2 text-white f-Varela rounded-md mx-auto'>RESET PASSWORD</button>
                        </div>
                    </div>
                </div>

            </>
        );
    }

}


const mapDispatchToProps = dispatch => {

    return {

        setLogin: user => dispatch({ type: "SET_LOGIN", payload: user })
    }
}

export default connect(null, mapDispatchToProps)(Forgot);