import React, { Component } from "react";
import { connect } from 'react-redux';
//import Structure from "../inc/structure";
import { Spinner} from 'react-bootstrap';
import Error from '../inc/Error';
import postApi from '../api_request/Post';
import { ServerLink } from '../keys/Links';
import notifyError from '../inc/SnackbarError';


class Password extends Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            new_password: '',
            old_password: '',
            confirm_password: '',
            fields: {},
            errors: {},
            isLoading: false
        }

    }



    handleInput = (e) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value });
    }


    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });

        //console.log(fields)
    }




    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;


        //phone number

        if (!fields["old_password"]) {
            formIsValid = false;
            errors["old_password"] = "Password cannot be empty";
        }


        if (fields["new_password"] !== fields['confirm_password']) {
            formIsValid = false;
            errors["confirm_password"] = "Password does not match, Confirm password agan " + fields["new_password"] + ' ' + fields['confirm_password'];
        }


        //password
        if (!fields["new_password"]) {
            formIsValid = false;
            errors["new_password"] = "Password cannot be empty";
        }

        if (typeof fields["new_password"] !== "undefined") {
            if (fields["new_password"].length <= '5') {
                formIsValid = false;
                errors["new_password"] = "Password length is too short, Min of 6 character";
            }
        }



        this.setState({ errors: errors });
        return formIsValid;
    }




    onSubmit = (e) => {

        e.preventDefault();


        if (this.handleValidation()) {

            this.setState({

                isLoading: true,
            })

            //proccess the form
            let fields = this.state.fields;

            const data = {
                password: fields['old_password'],
                new_password: fields['new_password'],

            }

            //console.log(data)

            postApi(ServerLink + 'api/update-password', data).then(
                res => {

                    if (res.status === true) {

                        this.setState({
                            isLoading: false,
                            errors: {
                                'error': ''
                            }
                        })
                        notifyError(res.message, 'success', 'top-center')
                    }
                    else {

                        notifyError(res.message.new_password ?? "Please specify a new Password diffrent from the Old Password", 'error', 'top-center');

                        this.setState({
                            isLoading: false,
                        })

                        console.log(res)
                    }
                }
            ).catch(e => {
                notifyError(e, 'error', 'top-center');

                this.setState({
                    isLoading: false,
                })

                console.log(e)
            })

        }

        else {
            console.log('Error')
        }
    }




    render() {

        return (
            <>
                <div className='md:w-8/12 mx-auto'>
                    <div className="homeBoard shadow-lg">
                        <div className="mx-5 py-3 workSanMedium border-b">
                            <h4>Update Password</h4>
                        </div>
                        <form className="" onSubmit={this.onSubmit}>
                            <div className="mx-5 mt-4 grid grid-cols-12 gap-4">

                                <div className="col-span-12">
                                    <label>Old Password</label>
                                    <Error error={this.state.errors['old_password'] ? this.state.errors['old_password'] : null} />
                                    <input name='old_password' onChange={this.handleChange.bind(this, "old_password")} type="password" className="px-3 py-2 border w-full focus:outline-none focus:bg-green-100" />
                                </div>
                                <div className="col-span-12">
                                    <label>New Password</label>
                                    <Error error={this.state.errors['new_password'] ? this.state.errors['new_password'] : null} />
                                    <input name='new_password' onChange={this.handleChange.bind(this, "new_password")} type="password" className="px-3 py-2 border w-full focus:outline-none focus:bg-green-100" />
                                </div>

                                <div className="col-span-12">
                                    <label>Confirm New Password</label>
                                    <Error error={this.state.errors['confirm_password'] ? this.state.errors['confirm_password'] : null} />
                                    <input name='confirm_password' onChange={this.handleChange.bind(this, "confirm_password")} type="password" className="px-3 py-2 border w-full focus:outline-none focus:bg-green-100" />
                                </div>



                                <div className="col-span-12 mb-10">
                                    {
                                        this.state.isLoading
                                            ?
                                            <button className="bg-black hover:bg-gray-500 text-white font-bold workSan md:w-auto w-full py-2">
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    className="mr-1"
                                                />
                                                Loading...
                                            </button>
                                            :
                                            <button className="primaryBg py-2 px-4 rounded-md text-white md:w-auto w-full">Update Password</button>
                                    }
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </>
        )
    }



}




const mapStateToProps = state => {

    return {
        loggedIn: state.auth.loggedIn,
        userInfo: state.auth.user

    }
}


const mapDispatchProps = dispatch => {

    return {
        loggout: () => dispatch({ type: 'SET_LOGOUT', })
    }
}

export default connect(mapStateToProps, mapDispatchProps)(Password);
