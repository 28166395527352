import React, { Component } from 'react';

// import NavBar from "../inc/Navbar";
import { Link } from "react-router-dom";

import cookie from 'js-cookie';
import { connect } from 'react-redux';
import postApi from '../api_request/Post';
import { ServerLink } from '../keys/Links';
import notifyError from '../inc/SnackbarError';
import NavBar from '../inc/Navbar';



class Login extends Component {

    constructor(props, context) {
        super(props, context);

        this.state = { email: '', password: '', errors: {}, show: false, isLoading: false }
    }

    handleInput = (e) => {
        e.preventDefault();

        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value })

    }


    handleForm = (e) => {
        e.preventDefault();
        if (this.state.email === "" || this.state.password === "") {

            notifyError('Email and Password Fields must be filled appropriately...', 'error', 'top-right')

        }

        else {

            this.setState({
                isLoading: true,
            })

            const data = {
                email: this.state.email,
                password: this.state.password,
            }

            postApi(ServerLink + 'api/login', data).then(
                res => {
                    //console.log(res)

                    if (res.status === false) {

                        notifyError(res.message, 'error', 'top-right')

                        this.setState({
                            isLoading: false
                        })
                    }
                    else {
                        //console.log(res)
                        cookie.set('token', res.token);
                        cookie.set('firstname', res.data.firstname);
                        cookie.set('lastname', res.data.lastname);
                        this.setState({
                            isLoading: false,
                            errors: {
                                'error': ''
                            }
                        });
                        //window.location.reload();
                        this.props.setLogin(res.data);
                        
                    }
                }
            ).catch(e => {
                console.log(e)
                notifyError('Oops: Network error', 'error', 'top-right')

                this.setState({
                    isLoading: false
                })
            })
        }
    }


    render() {
        const showPassword = () => {

            var getPassword = document.getElementById('password');

            if (getPassword.type === "password") {
                getPassword.type = "";
            } else {
                getPassword.type = "password";

            }
        }

        return (

            <>
                <div className='border-b'>
                    <div className='md:container mx-auto'>
                        <NavBar />
                    </div>
                </div>

                <div className='mt-20'>
                    <div className='md:w-4/12 mx-auto'>
                        <div>
                            <h2 className="primaryColor text-center biomeBold font-bold">Applicant Login</h2>
                        </div>

                        <div className="md:px-1 px-3 mt-5 mx-auto">

                            <div className="md:pt-2">

                                <form onSubmit={this.handleForm} method="post">
                                    <div className="f-Varela">
                                        <label className='text-gray-700'> E-mail </label>
                                        <input onChange={this.handleInput} type="email" className="w-full border border-gray-600  py-2 px-3 bg-gray-100 focus:bg-blue-100 focus:outline-none focus:border-blue-400" name="email" />
                                    </div>

                                    <div className="mt-4 f-Varela">
                                        <label className='text-gray-700'>Password</label>
                                        {/* <input  className="w-full border border-gray-600 py-2 px-3 bg-gray-100 focus:bg-green-100 focus:outline-none focus:border-green-700" placeholder="Password" name="password" /> */}
                                        <input onChange={this.handleInput} type="password" name="password" id="password" className="border w-full border-gray-600  py-2 px-3 bg-gray-100 focus:bg-blue-100 focus:outline-none focus:border-blue-700" />
                                        <button type="button" toggle="#password-field" onClick={showPassword} className="fa fa-fw fa-eye field-icon toggle-password inline-block absolute mt-3 focus:outline-none text-gray-500" style={{ marginLeft: '-45px' }}></button>
                                    </div>

                                    <div className="mt-5">
                                        {this.state.isLoading ?
                                            <button type="button" className="py-2 px-3 bg-black w-full text-center text-white rounded-md workSanBold">Loading ...</button>
                                            :
                                            <button type="submit" className="py-2 px-3 primaryBg w-full text-center text-white rounded-md workSanBold">Login</button>
                                        }
                                    </div>

                                    <div className="mt-4">
                                        <div className="flex flex-row gap-5">
                                            <div className="workSan flex-1">
                                                <p className="text-sm">Don’t have an account? <Link to="/register" className="primaryColor workSanBold">Signup Here</Link></p>
                                            </div>
                                            <div className="workSan">
                                                <p className="text-sm"><Link to="/forgotten-password" className="text-gray-700 workSanBold">Forgot your password?</Link></p>
                                            </div>
                                        </div>
                                    </div>



                                </form>
                            </div>

                        </div>

                    </div>
                </div>

            </>
        );
    }

}





const mapDispatchToProps = dispatch => {

    return {

        setLogin: user => dispatch({ type: "SET_LOGIN", payload: user })
    }
}

export default connect(null, mapDispatchToProps)(Login);





//export default Login;