import cookie from 'js-cookie';
//import { VerificationCode } from '../inc/Secret';

let token = cookie.get("token");


async function postApi(url = "", data = {}){

    const response = await fetch(url, {
        method: "POST",
        headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer "+token
        // 'Content-Type': 'multipart/form-data',
       // 'client-id': VerificationCode,
    },

    redirect: 'follow',
    body: JSON.stringify(data)
    })

     return response.json();
}


export default postApi


//Post Multipart for media

export async function postApiMultipart(url = "", data){

    var myHeaders = new Headers();
    //myHeaders.append('client-id', VerificationCode);
    myHeaders.append('Authorization', "Bearer "+token)

    const response = await fetch(url, {
        method: "POST",
        headers: myHeaders,
        redirect: 'follow',
        //body: JSON.stringify(data)
        body: data

    })

     return response.json();
}
