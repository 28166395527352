import React, { Component } from "react";
import { connect } from 'react-redux';

import Error from '../inc/Error';
import postApi from '../api_request/Post';
import { ServerLink } from '../keys/Links';
import notifyError from '../inc/SnackbarError';
import { Spinner } from 'react-bootstrap';
import NavBar from '../inc/Navbar';
import Dropzone from 'react-dropzone';
import cookie from 'js-cookie';





class UploadUBN extends Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            pitch_deck: false,
            files: [],
            fields: {},
            errors: {},
            isLoading: false,
            uploadSuccess: false
        }
    }


    acceptFiles = (acceptedFiles) => {

        console.log(acceptedFiles);

        this.setState({
            files: acceptedFiles.map(file => Object.assign(file, { preview: URL.createObjectURL(file) }))
        });

        const formData = new FormData();
        formData.append('file', acceptedFiles[0]);

        const xhr = new XMLHttpRequest();
        xhr.open('POST', ServerLink + 'api/upload-ubn', true);
        xhr.setRequestHeader('Authorization', `Bearer ${cookie.get('token')}`);

        xhr.upload.addEventListener('progress', (event) => {
            if (event.lengthComputable) {
                const percentage = (event.loaded / event.total) * 100;
                this.setState({ uploadProgress: percentage });
            }
        });

        xhr.onreadystatechange = () => {
            this.setState({ isLoading: true });
            if (xhr.readyState === XMLHttpRequest.DONE) {
                if (xhr.status === 200) {
                    const response = JSON.parse(xhr.responseText);
                    if (response.status === true) {

                        this.setState({ uploadSuccess: true })
                        
                        notifyError(response.message, 'success', 'top-center');


                        console.log(response);
                    } else {
                        notifyError(response.message, 'error', 'top-center');
                        this.setState({ uploadSuccess: false })

                        console.log(response);
                    }
                    this.setState({ isLoading: false });
                } else {
                    notifyError('Error occurred during file upload.', 'error', 'top-center');
                    this.setState({ isLoading: false });
                    this.setState({ uploadSuccess: false })

                    console.log(response);
                }
            }
        };

        xhr.send(formData);
    };


    submitUpload = ()=>{

        //check if the file has been uploaded successfully

        if(this.state.uploadSuccess === false){
            notifyError('Please upload your UBN file before you submit', 'error', 'top-center');
        }

        else{
            this.props.history.push('/complete')
        }
    }




    render() {


        const acceptedFileTypes = {
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
            'application/xlsx': ['.xlsx'],
            'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
            'application/pdf': ['.pdf'],
            'application/msword': ['.doc'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
            'application/vnd.ms-powerpoint': ['.ppt'],

        };

        return (
            <>
                <div className='border-bottom'>
                    <NavBar />
                </div>

                <section className='mt-5'>

                    <div className='container mx-auto mb-20'>
                        <div className='my-5'>
                            <h2 className='biomeBold font-extrabold text-center primaryColor mb-10'>UBN Form</h2>
                        </div>

                        <div className="grid grid-cols-12 gap-10">
                            <div className="col-span-7">
                                <div className="mt-32">
                                    <div className='mt-4'>
                                        <label className='primaryColor biomelight font-bold'> Company Name <span className='text-red-400'>*</span></label>
                                        <input className='w-full formBorder bg-gray-100 border-gray-500 rounded-md py-2 px-3 focus:bg-blue-100 focus:outline-none' defaultValue={this.state.company ?? this.props.userInfo.company} readOnly name='company' onChange={this.handleInput} />
                                        <Error error={this.state.errors["company"] ? this.state.errors["company"] : null} />
                                    </div>
                                </div>

                                <div className='mt-4'>
                                    <label className='primaryColor biomelight font-bold'>Upload your filled UBN form<span className='text-red-400'>*</span></label>
                                    <div>
                                        <Dropzone accept={acceptedFileTypes} onDrop={acceptedFiles => this.acceptFiles(acceptedFiles)}>
                                            {({ getRootProps, getInputProps }) => (
                                                <section className='border-2 border-gray-500 rounded-md border-dotted w-full text-center cursor-pointer biomelight text-sm'>
                                                    <div {...getRootProps()}>
                                                        <input name='file' {...getInputProps()} />
                                                        {
                                                            this.state.files.length ?
                                                                this.state.files.map(file => (
                                                                    <div className="flex items-center gap-4 px-3 py-2 w-full">
                                                                        <div className='w-16 h-16 bg-gray-200'>
                                                                        </div>
                                                                        <div className='flex flex-col gap-1 w-full'>
                                                                            <div className='flex justify-between items-center w-full'>
                                                                                <div className="flex flex-col items-start gap-2 text-[#494d50] text-sm">
                                                                                    <p className="font-bold mb-0">{(file.size / 1048576).toFixed(1)} <span className="font-normal">MB</span></p>
                                                                                    <p className="mb-0">{file.name}</p>
                                                                                </div>
                                                                                <button>
                                                                                    <svg stroke="1" fill="#8e98a2" clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="1" viewBox="0 0 24 24" width="40" height="40" xmlns="http://www.w3.org/2000/svg"><path d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 7.425 2.717-2.718c.146-.146.339-.219.531-.219.404 0 .75.325.75.75 0 .193-.073.384-.219.531l-2.717 2.717 2.727 2.728c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.384-.073-.53-.219l-2.729-2.728-2.728 2.728c-.146.146-.338.219-.53.219-.401 0-.751-.323-.751-.75 0-.192.073-.384.22-.531l2.728-2.728-2.722-2.722c-.146-.147-.219-.338-.219-.531 0-.425.346-.749.75-.749.192 0 .385.073.531.219z" fill-rule="nonzero" /></svg>
                                                                                </button>
                                                                            </div>
                                                                            <div className="relative rounded-sm w-0 h-1" style={{
                                                                                width: `${this.state.uploadProgress}%`,
                                                                                backgroundColor: "#4bce61",
                                                                            }}>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                )) : (
                                                                    <div className='flex flex-col gap-4 items-center justify-center py-5 px-10'>
                                                                        <svg fill="#6B7280" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M16 16h-3v5h-2v-5h-3l4-4 4 4zm3.479-5.908c-.212-3.951-3.473-7.092-7.479-7.092s-7.267 3.141-7.479 7.092c-2.57.463-4.521 2.706-4.521 5.408 0 3.037 2.463 5.5 5.5 5.5h3.5v-2h-3.5c-1.93 0-3.5-1.57-3.5-3.5 0-2.797 2.479-3.833 4.433-3.72-.167-4.218 2.208-6.78 5.567-6.78 3.453 0 5.891 2.797 5.567 6.78 1.745-.046 4.433.751 4.433 3.72 0 1.93-1.57 3.5-3.5 3.5h-3.5v2h3.5c3.037 0 5.5-2.463 5.5-5.5 0-2.702-1.951-4.945-4.521-5.408z" /></svg>
                                                                        <h6 className='mb-0 biomeBold primaryColor'>
                                                                            Drop your file here or click here to upload
                                                                        </h6>
                                                                        <p>Upload your filled UBN form</p>
                                                                    </div>
                                                                )
                                                        }
                                                    </div>
                                                </section>
                                            )}
                                        </Dropzone>

                                        <div className='my-5'>

                                            <div className='md:float-left'>

                                                {
                                                    this.state.isLoading ?
                                                        <button className='bg-black biomelight font-bold text-white px-3 py-2 rounded-full text-sm'>
                                                            <Spinner
                                                                as="span"
                                                                animation="border"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                                className="mr-1"
                                                            /> LOADING....
                                                        </button>
                                                        :
                                                        <>
                                                            <button onClick={this.submitUpload} className='primaryBg biomelight font-bold text-white px-5 py-3 rounded-full text-md'> Submit </button>
                                                            
                                                        </>
                                                }

                                            </div>

                                        </div>
                                    </div>
                                    <Error error={this.state.errors["pitch_deck"] ? this.state.errors["pitch_deck"] : null} />
                                </div>
                            </div>
                            <div className="col-span-5">
                                <div className="float-right text-center">
                                    <a className="py-2 px-3 primaryBg text-white" href="https://docs.google.com/spreadsheets/d/15D4YRLmHm0W-DIxUUh9bsV2QQRnfSvJY/edit?usp=sharing&ouid=103156099015151221538&rtpof=true&sd=true" target="new">
                                        DOWNLOAD YOUR UBN FORM TEMPLATE
                                    </a>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </>
        );
    }
}


//export default UploadUBN;



const mapStateToProps = state => {

    return {
        loggedIn: state.auth.loggedIn,
        userInfo: state.auth.user

    }
}


const mapDispatchProps = dispatch => {

    return {
        loggout: () => dispatch({ type: 'SET_LOGOUT', })
    }
}

export default connect(mapStateToProps, mapDispatchProps)(UploadUBN);
